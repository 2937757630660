@import '../../assets/styles/settings/global';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/tools/functions';
@import '../../assets/styles/tools/mixins';

$modal-tranform-y: remify(-100);

.modalContainer {
  height: 100vh;
  width: 100vw;
  background-color: palette(neutral, athens-gray);
  position: relative;
  padding-top: remify(50);
  padding-bottom: remify(30);
  padding-left: 10%;
  padding-right: 10%;
  overflow-y: scroll;
  overflow-x: hidden;
  animation-duration: 200ms;
  animation-name: slidein;
}

.modalContainer--smol {
  height: auto;
  max-height: 96vh;
  width: 75vw;
  margin: 1% auto;
  overflow-y: auto;
  background-color: palette(neutral, white);
  border-radius: $base-border-radius;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.5);
  &_smaller {
    width: 60vw;
    margin: 10% auto;
  }
}
.unMounting {
  animation-name: slideout;
}

.modalContainerCloseBtn {
  position: absolute;
  left: remify(20);
  top: remify(10);
  padding: 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: #0375ba;
  font-size: remify(48);
  line-height: remify(48);

  &--on-right {
    left: auto;
    right: remify(20);
  }
}

@keyframes slidein {
  from {
    opacity: 0;
    transform: translateY($modal-tranform-y);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideout {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY($modal-tranform-y);
  }
}
