@import '../../assets/styles/settings/global';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/tools/functions';
@import '../../assets/styles/settings/fonts';
@import '../../assets//styles/tools/mixins';

.iconLink {
  height: 30px;
}

.locationPageTitle {
  display: inline-flex;
  align-items: center;
  &_toast {
    padding: remify(8 25);
    border-radius: remify(15);
    background-color: palette(neutral, white);
    margin-left: remify(10);
    margin-top: remify(5);
    font-size: 18px;
  }
}
