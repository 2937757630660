@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/settings/fonts';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/tools/mixins';

.summary-grid {
  @include card(remify(32));
}

.summary-grid__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.summary-grid__list__item {
  background-color: palette(neutral, athens-gray);
  border-radius: 6px;
  display: flex;
  width: 100%;
  margin-bottom: remify(20);
  flex-direction: column;
  font-family: font(family, base);
  font-weight: font(weight, semibold);
  height: remify(75);
  justify-content: center;
  padding: remify(0 20);
  &:last-child {
    margin-bottom: 0;
  }
}

.summary-grid__list__item__label {
  color: palette(neutral, dark2);
  font-size: remify(14);
  line-height: remify(17);
  margin-bottom: 3px;
}

.summary-grid__list__item__value {
  color: palette(neutral, darker);
  font-size: remify(24);
  line-height: remify(29);
}

@include bpMedium() {
  .summary-grid__list__item {
    width: calc(50% - 10px);
    &:nth-child(3),
    &:nth-child(4) {
      margin-bottom: 0;
    }
  }
  .summary-grid__list__item:nth-child(odd) {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 1075px) {
  .summary-grid__list__item {
    width: calc(25% - 15px);
    margin-right: 20px;
    margin-bottom: 0;
  }
  .summary-grid__list__item:nth-child(4n) {
    margin-right: 0;
  }
}
