@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/settings/fonts';
@import '../../../../assets/styles/tools/mixins';

$COL_WIDTH--LG: 20%;

$COL_WIDTH: 50%;

.notification-card {
  display: flex;
  flex-direction: column;
  min-height: remify(100);
  background-color: palette(neutral, white);
  border-radius: remify(7);
  @include vr(1);

  @include bpMedium() {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.section {
  font-weight: font(weight, semibold);
  padding: remify(15 25);
  @include bpMedium() {
    padding: remify(25 30 30 25);
  }

  &:nth-child(1) {
    @include bpMedium() {
      width: $COL_WIDTH;
    }
    @include bpLarge() {
      width: $COL_WIDTH--LG;
    }
  }
  &:nth-child(2) {
    @include bpMedium() {
      width: $COL_WIDTH;
    }
    @include bpLarge() {
      width: $COL_WIDTH--LG;
    }
  }
  &:nth-child(3) {
    @include bpMedium() {
      width: $COL_WIDTH;
    }
    @include bpLarge() {
      width: $COL_WIDTH--LG;
    }
  }
  &:nth-child(4) {
    @include bpMedium() {
      width: $COL_WIDTH;
    }
    @include bpLarge() {
      width: $COL_WIDTH--LG;
    }
  }
  &:nth-child(5) {
    @include bpMedium() {
      width: $COL_WIDTH;
    }
    @include bpLarge() {
      width: $COL_WIDTH--LG;
    }
  }

  &__label {
    margin-bottom: remify(10);
    font-size: remify(14);
    color: palette(neutral, oslo-gray);
  }
}
