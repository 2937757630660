//----------------------------------------------------------------------------
// Settings: Fonts
//----------------------------------------------------------------------------

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../media/fonts/ProximaNova-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../media/fonts/ProximaNova-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../media/fonts/ProximaNova-Extrabold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../media/fonts/ProximaNova-ExtraboldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../media/fonts/ProximaNova-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../media/fonts/ProximaNova-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../media/fonts/ProximaNova-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../media/fonts/ProximaNova-SemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../media/fonts/ProximaNova-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../media/fonts/ProximaNova-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../media/fonts/ProximaNova-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../media/fonts/ProximaNova-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../media/fonts/ProximaNova-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../media/fonts/ProximaNova-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

// USAGE
//
// body {
//     font-family: font(family, base);
//     font-weight: font(weight, bold);
//     font-style: font(style, italic);
// }
//
$FONTS: (
  family: (
    base: #{'Proxima Nova',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    'Arial',
    sans-serif},
    code: #{source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace},
  ),
  weight: (
    thin: 100,
    light: 300,
    regular: 400,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  ),
  style: (
    normal: normal,
    italic: italic,
  ),
);
@function font($font, $style: 'regular') {
  @return map-get(map-get($FONTS, $font), $style);
}
