@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/tools/mixins';

.primaryNav_Menu_Link {
  align-items: center;
  border-radius: remify(10 0 0 10);
  color: palette(neutral, mine-shaft);
  display: block;
  font-size: remify(16);
  margin-bottom: 1px;
  margin-left: remify(12);
  margin-top: 1px;
  position: relative;
  transition: background-color 0.1s ease, color 0.1s ease;
  white-space: nowrap;

  .iconStyle {
    position: relative;
    left: remify(40);
    width: auto;
    height: remify(15);
  }
}

/* 
  IE11 Fixes: Flexbox in IE11 need to have defined widths.
  Both widths account for 12px of margin-left on .primaryNav_Menu_Link
*/
:global(.primaryNav) .primaryNav_Menu_Link {
  width: remify($primary-nav-expanded-width - 12);
}
:global(.primaryNav.is-collapsed) .primaryNav_Menu_Link {
  width: remify($primary-nav-collapsed-width - 12);
}

.primaryNav_Menu_Link:global(.active),
.primaryNav_Menu_Link:hover,
.primaryNav_Menu_Link:focus,
.primaryNav_Menu_Link:active {
  background-color: palette(neutral, athens-gray);
}

.primaryNav_Link {
  display: flex;
  align-items: center;
  padding: remify(20 0);
  cursor: pointer;

  @include bpLarge() {
    padding: remify(24 0);
  }
}

.primaryNav_Menu_Link_Text {
  margin-left: remify(12);
}

:global(.primaryNav.is-collapsed) .primaryNav_Menu_Link_Text {
  @include isVisuallyHidden;
}

.primaryNav_Menu_Link svg {
  color: palette(neutral, iron);
  overflow: initial;
  margin-left: remify(30);
}

:global(.active).primaryNav_Link > .primaryNav_Link_Icon {
  color: palette(brand, primary);
}
