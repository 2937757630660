@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/tools/mixins';
$ratio: 0.718;
@function calcWidth($ratio, $side) {
  @if $side == 'left' {
    @return (((100 * $ratio) - 100) * -1) * 1%;
  }

  @if $side == 'right' {
    @return (100 * $ratio) * 1%;
  }
}

.shipping {
  position: relative;
}

.order-shipping {
  &__options-container {
    // display: flex;
    // flex-direction: row;
  }

  &__radio-container {
    display: flex;
    flex-direction: row;
    height: 60%;
    align-items: center;
  }

  &__radio-account-container,
  &__radio-drop-container {
    display: flex;
    align-items: center;
    margin-right: 30px;

    label {
      margin-left: 10px;
    }
  }

  &__type-container {
    position: relative;
    width: calcWidth($ratio, 'left');
  }

  &__address-container {
    width: calcWidth($ratio, 'right');
  }
}

.order-shipping__form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  & > * {
    width: calc((100% / 3) - 20px);
  }
  // force last item left
  &::after {
    content: '';
    width: calc((100% / 3) - 20px);
  }
}

.shippingSelector {
  display: flex;
  flex-direction: row;
}

.shippingSelector__btn {
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-right: 50px;

  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: auto;
  }
}

.shippingSelector__radio {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 2px solid palette(neutral, gray-chateau);
  background: transparent;
  margin-right: 10px;

  &::after {
    content: '';
    position: absolute;
    display: flex;
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    margin-left: -5px;
    margin-top: -5px;
    transform: scale(0);
    transform-origin: center;
    background: white;
    border-radius: 100%;
    border: 0px solid transparent;
    transition: all 255ms cubic-bezier(0.29, 0.19, 0.405, 1);
  }

  &--selected {
    background: palette(brand, primary);
    border: palette(brand, primary);
    &::after {
      transform: scale(1);
      background: white;
    }
  }
}

.dropShipAddressSelection {
  background: transparent;
  display: flex;
  justify-content: space-between;
  width: 100%;

  h2 {
    margin-bottom: 30px;
  }

  > * {
    width: calc((100% / 2) - 10px);
  }
}

.dropShipAddressSelection__currentAddress {
  background: transparent;
}

.dropShipAddressSelection__suggestedAddresses {
  background: transparent;
  position: relative;
}

.dropShipAddressSelection__suggestedAddressesContainer {
  height: 300px;
  overflow: auto;
}

.dropShipAddressSelection__cardSelected {
  @include card(remify(40 36));
  display: flex;
  background: palette(neutral, white);
  cursor: pointer;
  margin-bottom: 20px;
  border: 2px solid palette(brand, primary);

  .suggestedAddress__radioSymbolContainer > .suggestedAddress__radioSymbol {
    background: palette(brand, primary);
    border: palette(brand, primary);
    &::after {
      transform: scale(1);
      background: white;
    }
  }
}

.dropShipAddressSelection__card {
  @include card(remify(40 36));
  display: flex;
  background: palette(neutral, white);
  cursor: pointer;
  margin-bottom: 20px;
  border: 2px solid palette(neutral, iron);

  &:hover {
    // color: palette(neutral, white);
    border: 2px solid palette(brand, primary);
  }

  &:hover > .suggestedAddress__radioSymbolContainer > .suggestedAddress__radioSymbol {
    background: palette(brand, primary);
    border: palette(brand, primary);
    &::after {
      transform: scale(1);
      background: white;
    }
  }
}

.suggestedAddress__radioSymbolContainer {
  height: auto;
  width: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.suggestedAddress__radioSymbol {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 2px solid palette(neutral, gray-chateau);
  background: transparent;
  margin-right: 10px;

  &::after {
    content: '';
    position: absolute;
    display: flex;
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    margin-left: -5px;
    margin-top: -5px;
    transform: scale(0);
    transform-origin: center;
    background: white;
    border-radius: 100%;
    border: 0px solid transparent;
    transition: all 255ms cubic-bezier(0.29, 0.19, 0.405, 1);
  }

  // &:hover {
  //   background: palette(brand, primary);
  //   border: palette(brand, primary);
  //   &::after {
  //     transform: scale(1);
  //     background: white;
  //   }
  // }
}

.dropShipAddressSelection__back {
  color: palette(brand, primary);
  font-weight: bold;
  cursor: pointer;
  background: transparent;
  border: none;

  svg {
    margin-right: remify(12);
    transform: rotate(90deg) scale(1.2, 1.4);
    fill: palette(brand, primary);
    color: palette(brand, primary);
  }
}

.shipping__loadingContainer {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.75);
  z-index: 99;

  > * {
    margin-top: 150px;
    transform: scale(1.25);
  }
}

.errors {
  background: palette(status, error);
  border-radius: 10px;
  padding: 30px;
  margin: 30px 0;
  color: palette(neutral, white);
}

.errors__line {
  margin-bottom: 10px;
  font-weight: bold;
}
