.table__header {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: remify(10);

  .hdg--3 {
    margin-bottom: 0;
  }

  @include bpXLarge() {
    flex-direction: row;
    justify-content: space-between;
  }
}

.table__header__with-message {
  display: flex;
  justify-content: center;
  align-items: center;
  & span {
    margin-left: 20px;
  }
}

.table__header__filters {
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;

  & > * {
    margin-bottom: remify(10);
    margin-right: remify(10);
  }

  @include bpLarge() {
    align-self: flex-end;
    flex-wrap: nowrap;
  }
}
.table__header__filters__end {
  @extend .table__header__filters;
  justify-content: end;
}
.table__header__filters__end__top {
  @extend.table__header__filters__end;
  display: flex;
  align-self: baseline;
}
.table__filter {
  appearance: none;
  background-color: palette(neutral, white);
  color: palette(brand, primary);
  cursor: pointer;
  background-image: url('../../media/icons/icon-caret-down--brand-primary.svg');
  background-repeat: no-repeat;
  background-position: right remify(16) top 50%, 0 0;
  background-size: remify(24) auto, 100%;
  border: 1px solid palette(neutral, gray-chateau);
  height: remify(48);
  min-width: remify(150);
  max-width: remify(250);
  padding: 0 remify(20);

  &.table__filter--wide {
    width: remify(230);
  }

  &[data-is-selected='true'],
  &:hover,
  &:focus {
    background-color: palette(brand, primary);
    background-image: url('../../media/icons/icon-caret-down--white.svg');
    color: palette(neutral, white);
  }

  // Make focus more visible since default focus-ring matches background-color
  [data-whatinput='keyboard'] &[data-is-selected='true']:focus {
    box-shadow: 0 0 5px 5px palette(brand, secondary);
  }

  &::-ms-expand {
    display: none;
  }
}
