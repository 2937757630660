@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/settings/fonts';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/tools/mixins';

.error-card {
  background-color: palette(neutral, white);
  border-radius: remify(7);
  display: grid;
  grid-template-columns: remify(60) auto;
  padding: remify(30);
  align-items: center;
  word-break: break-word;
}

.error-card--on-light {
  @extend .error-card;

  background-color: palette(neutral, dat-gray);
}

.error-card__icon {
  width: remify(35);
  height: remify(35);
}
.error-card__error-message {
  font-weight: bold;
  margin-bottom: remify(3);
}
.error-card__secondary-message {
  font-weight: normal;
}

.error_buttonContainer {
  display: flex;
  align-items: center;
}

.error_btn {
  margin-left: remify(24);
}

.error_text {
  margin-top: remify(16);
}
