@import '../../../assets/styles/settings/global';
@import '../../../assets/styles/settings/colors';
@import '../../../assets/styles/tools/functions';
@import '../../../assets/styles/settings/fonts';
@import '../../../assets/styles/tools/mixins';

.userAccountMenuContentWrapper {
  @include defaultPopper;
}

.userAccountMenu__button {
  font-weight: bold;
  color: palette(status, error);
  background: transparent;
  border: none;
  cursor: pointer;
}

.userAccountMenu__icon {
  background: transparent;
  border: none;
  margin-top: 5px;
  svg {
    g {
      color: palette(brand, primary);
      fill: palette(brand, primary);
    }
  }
}
