@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/settings/fonts';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/tools/mixins';

$acc-table-border-radius: 7px;

.acc-table-wrapper {
  position: relative;
}

.acc-table {
  border-collapse: separate;
  border-spacing: remify(0 8);
  padding-bottom: remify(20);
  table-layout: fixed;
  width: 100%;
}

.acc-split {
  width: 60%;
  margin-right: remify(50);
}

.acc-table__sort-btn {
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  font-family: font(family, base);
  font-weight: font(weight, semibold);
  padding: remify(14 2);
  text-align: left;

  &:active {
    color: currentColor;
  }

  .icon {
    margin-left: remify(10);
  }
}

.acc-table__row--bd {
  position: relative;
  background-color: palette(neutral, white);
  border-color: palette(neutral, athens-gray);
  color: palette(neutral, shark);
  cursor: pointer;
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
  height: 100%;

  &:first-child {
    border-top: 0;
  }

  &.is--active {
    background-color: palette(brand, primary);
    color: palette(neutral, white);
    transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
  }

  &.is--hidden {
    display: none;
  }
}

.acc-table__row__cell {
  padding-right: remify(10);
  text-align: left;

  &:nth-child(1) {
    max-width: remify(200);
    width: 20%;
  }
  &:nth-child(2) {
    width: 37%;
  }
  &:nth-child(3) {
    width: 43%;
  }
}

.acc-table__row--hd .acc-table__row__cell {
  &:first-child {
    padding-left: remify(18);
  }
  &:last-child {
    padding-right: remify(18);
  }
}

.acc-table__row--bd .acc-table__row__cell {
  font-weight: font(weight, semibold);
  font-size: remify(18);
  line-height: $base-line-height;
  padding-top: remify(19);
  padding-bottom: remify(19);
  vertical-align: middle;

  &:first-child {
    border-top-left-radius: $acc-table-border-radius;
    border-bottom-left-radius: $acc-table-border-radius;
    padding-left: remify(18);
  }
  &:last-child {
    border-top-right-radius: $acc-table-border-radius;
    border-bottom-right-radius: $acc-table-border-radius;
    padding-right: remify(18);
  }
}

.acc-table__row__cell__split {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.acc-table__hover-label {
  background-color: palette(neutral, white);
  border-radius: remify(17);
  color: palette(brand, primary);
  display: inline-block;
  opacity: 0;
  padding: remify(6 0);
  text-align: center;
  width: remify(95);
  &.bigger_label {
    width: remify(150);
  }
}

.is--active .acc-table__hover-label {
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
}

.acc-table__hover-icon-container {
  background-color: palette(neutral, white);
  border-radius: 100px;
  color: palette(brand, primary);
  display: flex;
  opacity: 0;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.acc-table__hover-icon-label {
  text-align: center;
}

.is--active .acc-table__hover-icon-container {
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
}

@include bpLarge() {
  .acc-table {
    margin-top: remify(20);
  }

  .acc-table__row--hd .acc-table__row__cell {
    &:first-child {
      padding-left: remify(36);
    }
    &:last-child {
      padding-right: remify(25);
    }
  }

  .acc-table__row--bd .acc-table__row__cell {
    &:first-child {
      padding-left: remify(36);
    }
    &:last-child {
      padding-right: remify(25);
    }
  }
}
