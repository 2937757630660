@import '../../assets/styles/settings/global';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/settings/fonts';
@import '../../assets/styles/tools/functions';
@import '../../assets/styles/tools/mixins';

.serial-table-card {
  background-color: #ffffff;
  border-radius: 7px;
  margin-bottom: 1.25rem;
}
.serial-detail-table {
  border-radius: $base-border-radius;
  width: 100%;
  line-height: 1.5;
}

.serial-detail-table__row--hd {
  border-bottom: 1px solid palette(neutral, iron);
  font-weight: font(weight, semibold);
}

.serial-detail-table__row--bd {
  background-color: palette(neutral, white);
  display: grid;
  border-bottom: 1px solid palette(neutral, iron);

  &:hover {
    background-color: palette(neutral, iron);
    cursor: pointer;
  }
}

.serial-detail-table__row__cell {
  font-size: remify(15);
  overflow: hidden;
  vertical-align: middle;
  width: 25%;
  padding-top: remify(26);
  padding-bottom: remify(26);
  padding-left: 50px;
  border-top: 1px solid palette(neutral, iron);
  @include bpXLarge() {
    font-size: remify(16);
  }
}

.serial-detail-table__row__cell__label {
  font-weight: font(weight, semibold);
  margin-right: remify(10);
  vertical-align: middle;
  padding-left: 50px;
}

.serial-detail-table__row--bd .serial-detail-table__row__cell:first-child {
  padding-top: remify(24);
}

.serial-detail-table__row__cell:last-child {
  padding-bottom: remify(24);
}

.serial-detail-table__row,
.serial-detail-table__row--hd,
.serial-detail-table__row--bd {
  display: table-row;
}

@include bpLarge() {
  .serial-detail-table__row--hd {
    height: remify(70);
  }

  .serial-detail-table__row--bd {
    @include bpLarge() {
      height: remify(70);
    }

    @include bpXLarge() {
      height: remify(85);
    }
  }

  .serial-detail-table__row__cell__category {
    color: palette(neutral, gray-chateau);
    text-transform: capitalize;
  }

  .text--truncate {
    display: block;
  }
}
.inline {
  display: inline;
}
.inline-bold {
  display: inline;
  font-weight: bold;
}
.left-align {
  text-align: left;
}
.center-align {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.grey {
  color: palette(neutral, oslo-gray);
}
.blue {
  color: palette(brand, primary);
}
.selected-serial-table-row {
  background-color: palette(brand, primary);
  color: palette(neutral, white);
  padding: remify(26);
  padding-left: 50px;
}
.sort-btn {
  display: flex;
  align-items: center;
  padding-left: 0px;
}
.buttonTextPadding {
  padding: 5px 0px 5px 0px;
}
.serial-icon {
  padding-right: 10px;
  display: flex;
}
.accordian-container-header {
  padding-top: remify(26);
  display: flex;
}
.accordian-item-container {
  width: 50%;
  display: flex;
}
.accordian-spec-container {
  padding-bottom: 25px;
}
.accordian-container {
  display: flex;
}
.bold {
  font-weight: 600;
  width: auto;
}
.bold-title {
  font-weight: 600;
  font-size: 20px;
  width: auto;
  display: flex;
  align-items: center;
}
.accordian-header {
  padding-bottom: 16px;
}
.title-padding {
  padding-right: 75px;
}
.attribute {
  width: auto;
  font-weight: 400;
}
.comment-container {
  padding: 5px 40px;
}
.copy-btn-container {
  display: flex;
  justify-content: flex-start;
  padding-top: 25px;
}
.serial-error-message {
  padding: remify(10);
}
.copy-btn-padding {
  padding: 20px;
}
.copy-all-btn-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}
.accordian-item-top-padding {
  padding-top: 10px;
}
.accordian-container-side-padding {
  padding-left: 50px;
  padding-right: 50px;
}
.flex {
  display: flex;
}
.flex-center {
  display: flex;
  align-items: center;
}
.half-border-left {
  width: 50%;
  padding-right: 50px;
}
.half-border-right {
  width: 50%;
}
.title-bottom-padding {
  padding-bottom: 10px;
}
.icon-margin {
  margin-right: 10px;
}
.empty-attribute {
  &:after {
    content: '.';
    visibility: hidden;
  }
}
.comment-div {
  width: 50%;
}
.cpy-btn {
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
}
.serial-card {
  @include card(remify(40 36));
  margin-bottom: remify(75);
  padding: remify(40 36);
}
