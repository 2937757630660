@import '../../assets/styles/settings/global';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/settings/fonts';
@import '../../assets/styles/tools/functions';
@import '../../assets/styles/tools/mixins';

.cardContainer {
  background: transparent;
  display: flex;
  @include card(remify(20 30));
  width: 100%;
  margin-bottom: 10px;
  position: relative;
  align-items: center;

  > * {
    margin-right: remify(20);
  }
}

.cardContainer--buttonPresent {
  cursor: pointer;

  &:hover {
    background: palette(brand, primary);
    color: palette(neutral, white);
  }

  &:hover > .buttonColumn > .buttonText {
    display: block;
    background: white;
    color: palette(brand, primary);
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 100px;
  }

  &:hover > .buttonColumn > .iconButton {
    display: inline-block;
    background: white;
    color: palette(brand, primary);
    font-weight: bold;
    padding: 10px;
    border-radius: 100px;
    width: auto;
    margin-left: auto;
  }
}

.buttonText,
.iconButton {
  display: none;
}

.buttonColumn {
  margin-left: auto;
}

.iconButtonColumn {
  display: flex;
}

.column {
  background: transparent;
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  min-width: 100px;
}

.label {
  background: transparent;
  font-weight: bold;
}

.data {
  background: transparent;
}
