@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/tools/mixins';
@import '../../../../assets/styles/settings/fonts';

.wrapper {
  display: inline-flex;
  flex-wrap: wrap;
  background-color: palette(neutral, white);
  border-radius: 4px;
  padding: remify(32);
  min-width: remify(360);
  max-width: 100%;

  @include bpLarge() {
    padding: remify(34 18 24 50);
  }
  > .entry {
    margin-bottom: remify(20);
    margin-right: remify(50);
    @media (min-width: $viewport-width-lg) {
      margin-bottom: 0;
    }
    .label {
      text-transform: capitalize;
      font-size: remify(16);
      font-weight: font(weight, semibold);
      line-height: remify(32);
      color: palette(neutral, oslo-gray);
    }
    .last {
      text-transform: capitalize;
      font-size: remify(14);
      font-weight: font(weight, semibold);
      line-height: remify(12);
      color: palette(neutral, oslo-gray);
    }
    .value {
      font-size: remify(72);
      font-weight: font(weight, semibold);
      line-height: remify(87);
      color: palette(neutral, shark);
    }
    .tooltip {
      text-transform: none;
      cursor: pointer;
      position: relative;
    }
    .tooltip:hover .tooltipBody {
      display: block;
    }
    .tooltipBody {
      box-shadow: 0 2px 16px 9px rgba(0, 0, 0, 0.05);
      display: none;
      position: absolute;
      left: remify(20);
      background-color: palette(neutral, white);
      padding: remify(20);
      min-width: remify(200);
      border-radius: remify(10);
      border-top-left-radius: 0;
      line-height: $base-line-height;
    }
  }
}

// These styles remove colors, paddings and weights so the summary
// can fit on a wider variety of backgrounds.
.unboxed {
  background-color: transparent;
  padding: 0;
  > .entry .label {
    color: palette(neutral, iron);
  }
  > .entry .last {
    color: palette(neutral, iron);
  }
  > .entry .value {
    color: palette(neutral, white);
  }
  > .entry .tooltipBody {
    color: palette(neutral, mine-shaft);
  }
}
