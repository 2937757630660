@import '../../assets/styles/settings/global';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/settings/fonts';
@import '../../assets/styles/tools/functions';
@import '../../assets/styles/tools/mixins';

.footer {
  @include wrapper();
  text-align: center;
  padding-top: 0;
}

.footer__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: remify(14);

  li + li {
    margin-top: remify(20);
  }

  a {
    font-weight: font(weight, semibold);
  }
}

.footer__list--inverse {
  li,
  a {
    color: palette(neutral, white);
  }
}

.timeZoneNote {
  color: palette(neutral, grey);
  margin-bottom: remify(20);
}

// unique breakpoint based on footer content
@media (min-width: 1100px) {
  .footer {
    display: flex;
    justify-content: center;
  }

  .footer__list {
    flex-direction: row;

    li + li {
      margin-top: 0;
      margin-left: remify(30);
    }
  }
}

@include bpXLarge() {
  .footer__list {
    li + li {
      margin-left: remify(40);
    }
  }
}
