//----------------------------------------------------------------------------
// Elements: Base
//----------------------------------------------------------------------------
html {
  font-size: 100%;
  overflow-y: auto;
}

body {
  background-color: palette(neutral, athens-gray);
  font-family: font(family, base);
  font-weight: font(weight, regular);
  font-style: font(style, normal);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Fix font-family not being inherited on form elements
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}

code {
  font-family: font(family, code);
}

svg {
  display: inline-block; // Safari doesn't respect inline
}

#modal-root {
  z-index: $z-index-modal;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
}

.modal-open {
  overflow: hidden;
}
