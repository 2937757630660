@import '../../assets/styles/settings/global';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/settings/fonts';
@import '../../assets/styles/tools/functions';
@import '../../assets/styles/tools/mixins';

.wrapper {
  min-height: remify(100);
  position: relative;
  pointer-events: none;
}

.small-wrapper {
  min-height: remify(20);
  position: relative;
  pointer-events: none;
}

:global(.full-height) {
  height: 100vh;
}

:global(.accounts-loader) {
  min-height: remify(200);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  .loader-container {
    background-color: rgba(239, 240, 244, 0.85); // athens-gray
    justify-content: flex-start;
    padding-top: remify(80);
  }
}

:global(.search-loader) {
  margin-top: remify(200);
  margin-left: auto;
  margin-right: auto;
}

.loader-container {
  align-items: center;
  background-color: transparent;
  bottom: 0;
  color: palette(neutral, white);
  content: '';
  cursor: wait;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: $z-index-loader;
}

.inverted {
  color: palette(brand, secondary);
}

.loader-container__message {
  color: palette(neutral, shark);
  display: block;
  font-size: remify(18);
  font-weight: font(weight, semibold);
  margin-top: remify(10);
}

// Adapted from https://loading.io/css/
.lds-roller {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 30px 30px;
}
.lds-roller div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: currentColor;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 47px;
  left: 47px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 51px;
  left: 42px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 53px;
  left: 36px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 54px;
  left: 30px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 53px;
  left: 24px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 51px;
  left: 18px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 47px;
  left: 13px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 42px;
  left: 9px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: palette(brand, secondary);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.spinner {
  width: 200px;
  text-align: center;
}

.spinner > div {
  width: 15px;
  height: 15px;
  margin: 0 5px;
  background-color: palette(brand, primary);

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/**
* SK CHASE
**/

.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
  // hover dots not fully implemented
  // &:hover > * {
  //   &::before {
  //     background-color: var(--hoverColor);
  //   }
  // }
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 20%;
  height: 20%;
  background-color: var(--colorVar);
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

// @keyframes sk-chase-dot-before {
//   50% {
//     transform: scale(0.4);
//   } 100%, 0% {
//     transform: scale(1.0);
//   }
// }

/*  Pulse
      <div class="sk-pulse"></div>
 */
.sk-pulse {
  width: 40px;
  height: 40px;
  background-color: palette(brand, primary);
  border-radius: 100%;
  animation: sk-pulse 1.2s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

@keyframes sk-pulse {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

/*  Circle Fade
      <div class="sk-circle-fade">
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
      </div>
 */
.sk-circle-fade {
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-circle-fade-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-circle-fade-dot:before {
  content: '';
  display: block;
  width: 15%;
  height: 15%;
  background-color: palette(brand, primary);
  border-radius: 100%;
  animation: sk-circle-fade 1.2s infinite ease-in-out both;
}

.sk-circle-fade-dot:nth-child(1) {
  transform: rotate(30deg);
}
.sk-circle-fade-dot:nth-child(2) {
  transform: rotate(60deg);
}
.sk-circle-fade-dot:nth-child(3) {
  transform: rotate(90deg);
}
.sk-circle-fade-dot:nth-child(4) {
  transform: rotate(120deg);
}
.sk-circle-fade-dot:nth-child(5) {
  transform: rotate(150deg);
}
.sk-circle-fade-dot:nth-child(6) {
  transform: rotate(180deg);
}
.sk-circle-fade-dot:nth-child(7) {
  transform: rotate(210deg);
}
.sk-circle-fade-dot:nth-child(8) {
  transform: rotate(240deg);
}
.sk-circle-fade-dot:nth-child(9) {
  transform: rotate(270deg);
}
.sk-circle-fade-dot:nth-child(10) {
  transform: rotate(300deg);
}
.sk-circle-fade-dot:nth-child(11) {
  transform: rotate(330deg);
}
.sk-circle-fade-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
.sk-circle-fade-dot:nth-child(2):before {
  animation-delay: -1s;
}
.sk-circle-fade-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.sk-circle-fade-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.sk-circle-fade-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.sk-circle-fade-dot:nth-child(6):before {
  animation-delay: -0.6s;
}
.sk-circle-fade-dot:nth-child(7):before {
  animation-delay: -0.5s;
}
.sk-circle-fade-dot:nth-child(8):before {
  animation-delay: -0.4s;
}
.sk-circle-fade-dot:nth-child(9):before {
  animation-delay: -0.3s;
}
.sk-circle-fade-dot:nth-child(10):before {
  animation-delay: -0.2s;
}
.sk-circle-fade-dot:nth-child(11):before {
  animation-delay: -0.1s;
}

@keyframes sk-circle-fade {
  0%,
  39%,
  100% {
    opacity: 0;
    transform: scale(0.6);
  }
  40% {
    opacity: 1;
    transform: scale(1);
  }
}
