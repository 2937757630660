.text--truncate {
  @include truncate();
}

.text--break {
  word-break: break-all;
  white-space: normal;
}

.bold {
  font-weight: bold;
}
