@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/settings/fonts';
@import '../../../../assets/styles/tools/mixins';

$BOX_MARGIN: remify(16);
$COL_1_WIDTH: unquote('calc(25% - #{$BOX_MARGIN})');
$COL_2_WIDTH: unquote('calc(25% - #{$BOX_MARGIN})');
$COL_3_WIDTH: unquote('calc(50% - #{$BOX_MARGIN})');

.order-items {
  @include vr(2.5);
  border-radius: remify(7);
  overflow: hidden;

  & > * {
    display: flex;
    flex-wrap: wrap;
    background-color: palette(neutral, white);
  }
}

.order-items__hd {
  border-bottom: 1px solid palette(neutral, iron);
  padding: remify(36);
}

.order-items__bd {
  padding: remify(0 32 32 32);
  border-bottom-left-radius: remify(7);
  border-bottom-right-radius: remify(7);
}

.order-items__total {
  display: flex;
  width: 100%;
  padding: remify(15) 0;
  margin-top: remify(15);
  align-items: center;
  justify-content: center;
  border-top: 1px solid palette(neutral, iron);
  border-bottom: 1px solid palette(neutral, iron);

  span {
    font-weight: 600;
    margin-right: remify(30);
  }
}

.section {
  background-color: palette(neutral, iron);
  border-radius: 6px;
  height: remify(75);
  margin-right: $BOX_MARGIN;
  padding-left: remify(18);

  &:nth-child(n + 4) {
    margin-top: remify(16);
  }
  &:nth-child(1),
  &:nth-child(4) {
    width: $COL_1_WIDTH;
  }
  &:nth-child(2),
  &:nth-child(5) {
    width: $COL_2_WIDTH;
  }
  &:nth-child(3),
  &:nth-child(6) {
    width: $COL_3_WIDTH;
  }

  &__label {
    padding-top: remify(14);
    margin-bottom: remify(6);
    font-size: remify(14);
    font-weight: font(weight, semibold);
    color: palette(neutral, gray);
  }

  // raise specificity to override Value component styles
  & > &__value {
    font-size: remify(24);
    font-weight: font(weight, semibold);
    line-height: $base-line-height;
  }
}

// Tracking Popper
.tracking-popper {
  @include defaultPopper;
}

.trackingContainer {
  display: flex;
  width: 100%;
}

.trackingCard {
  @include card(remify(40 36));
  margin: 20px;
  overflow: hidden;
  width: 100%;
}

.trackingColumn {
  background: transparent;
  margin-right: remify(100);
}

.trackingBg {
  background: palette(neutral, iron);
}

.trackingItemInfo {
  background: transparent;
  margin-top: 50px;
  position: relative;

  &::before {
    content: '';
    width: 1300%;
    height: 1px;
    background: palette(neutral, iron);
    position: absolute;
    top: -25px;
    left: -100px;
    z-index: 999;
  }
}

.trackingArrow {
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: palette(brand, primary);
  cursor: pointer;
}

.trackingArrowIcon {
  $iconDimensions: 10px;
  background: transparent;
  width: $iconDimensions;
  height: $iconDimensions;
  margin-right: 10px;
  margin-left: 40px;
  transform: rotate(90deg);
}
