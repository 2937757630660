@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/settings/fonts';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/tools/mixins';

.productsWrapper {
  margin-left: remify(-10);
}
.wrapper {
  height: 170px;
  width: 170px;
  border-radius: remify(10);
  display: flex;

  background-color: palette(neutral, lightgrey);
  color: palette(brand, primary);
  cursor: pointer;
  float: left;
  margin: remify(10);
  box-shadow: 0px 1px 5px 2px lightgrey;

  &:hover,
  &:focus {
    color: palette(neutral, mine-shaft);
    fill: palette(neutral, mine-shaft);
  }

  &:hover .image,
  &:focus .image {
    fill: palette(neutral, mine-shaft);
  }
}

.statusBar {
  width: remify(12);
  height: 100%;
  background-color: gray;
  border-top-left-radius: remify(8);
  border-bottom-left-radius: remify(8);
  &[data-status='past due'] {
    background-color: palette(status, error);
  }
  &[data-status='open'] {
    background-color: palette(status, warning);
  }
  &[data-status='closed'] {
    background-color: palette(status, complete);
  }
}

.content {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.status {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: font(weight, semibold);
  &[data-status='past due'] {
    color: palette(status, error);
  }
  &[data-status='open'] {
    color: palette(status, warningText);
  }
  &[data-status='closed'] {
    color: palette(status, completeText);
  }
}

.status,
.number {
  font-size: remify(14);
}

.nameWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 30%;
}
.name {
  font-weight: font(weight, bold);
  padding-top: remify(2);
}
.balance {
  font-size: remify(24);
  font-weight: font(weight, semibold);
  color: palette(neutral, mine-shaft);
}

.imageWrap {
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 50%, rgba(242, 242, 242, 1) 100%);
  display: flex;
  flex-basis: 70%;
  align-items: center;
  justify-content: center;
  border-radius: remify(10) remify(10) 0 0;
}
.image {
  width: 70px;
  height: 70px;
  padding: 0;
  fill: palette(brand, primary);
}

.itemAddedWrapper {
  display: flex;
}

.itemAddedIcon {
  padding-right: remify(10);
}
