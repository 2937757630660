@import '../../../../assets/styles/settings/colors';
.order-review__total-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-top: 40px;
}

.order-review__total-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 350px;
  margin-top: 10px;
}

.form__inputContainer {
  display: flex;
  flex-direction: column;
  margin: 12px 0px 32px 0px;
  background: white;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  padding: 10px 20px;
  max-width: 50%;
  &:focus-within {
    border: 2px solid palette(brand, bright-blue);
  }
}
.input__label {
  font-size: 16px;
  margin-bottom: 5px;
  color: #4a4a4a;
}

.input {
  font-size: 20px;
  background: transparent;
  border-radius: none;
  border: none;
  outline: none;
}

.poInputGroup {
  margin-top: 32px;
}

.shipToLabel {
  margin-bottom: 5px;
}
