@import '../../../../assets/styles/settings/_colors.scss';
@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/fonts';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/tools/mixins';

.dynamic-form {
  position: relative;
  width: 100%;
}

.dynamic-form__hr-top {
  @include breakContainer;

  margin-top: remify(20);
  height: 1px;
  border: none;
  margin-bottom: 0;
  background: palette(neutral, iron);
}

.dynamic-form__container {
  width: 100%;
  display: flex;
}

.dynamic-form__left {
  width: 66.666%;
}

.dynamic-form__non-master-fields {
  width: calc(50% - 12px);
  margin-bottom: 50px;
}

.dynamic-form__fields-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  > * {
    width: calc(50% - 12px);
    margin-bottom: 24px;
  }
  align-items: flex-end;
}

.dynamic-form__controls-container {
  width: 100%;
}

.dynamic-form__reset-btn {
  margin-top: 30px;
  width: 100%;
  margin: 30px auto;
}

.dynamic-form__error {
  position: relative;
  background: palette(status, error);
  padding: remify(20);
  border-radius: remify(10);
  color: palette(neutral, white);
  font-weight: 600;
  text-align: center;
}

.dynamic-form__right {
  width: calc(33.33% + 35px);
  transform: translateX(35px);
  border-left: 1px solid palette(neutral, iron);
  background: palette(neutral, dat-gray);
  // padding: remify(35);
}

.dynamicFormPanel {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.preview-panel__image-container,
.preview-panel__summary-container {
  padding: 35px;
}

.preview-panel__image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid palette(neutral, iron);

  svg {
    max-height: 100px;
    color: palette(brand, primary);
    fill: palette(brand, primary);
  }
}

.preview-panel__grid-container {
  display: grid;
  grid-template-columns: 50% 50%;
  row-gap: 10px;
}

.title_withLoading {
  display: flex;
  align-items: center;
}

.priceContainer {
  margin-top: auto;
}

.priceInnerContainer {
  background: transparent;
  padding: remify(35);
  border-top: 1px solid palette(neutral, iron);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.price {
  background: transparent;
}

.priceLabel {
  margin-bottom: remify(6);
  font-size: remify(14);
  font-weight: font(weight, semibold);
  color: palette(neutral, gray);
}
.inline {
  display: inline;
}
