@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/settings/fonts';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/tools/mixins';

.templateContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: remify(80);
}

.templateSubTitle {
  margin: remify(20 0);
}
