//----------------------------------------------------------------------------
// Settings: Global
//
// Most variables are integer values only; remove 'px' for use with remify()
//----------------------------------------------------------------------------

// Animations
$transition-timing: 0.2s;

// Breakpoints
$viewport-width-md: 768px;
$viewport-width-lg: 1025px;
$viewport-width-xl: 1440px;

// Landmarks
$primary-nav-collapsed-width: 100;
$primary-nav-expanded-width: 230;

// Spacing and Alignment
$base-vertical-rhythm: 10;
$main-content-width: 1044px;

// Decoration
$base-border-radius: 7px;

// Text
$base-font-size: 16;
$base-line-height: 1.231; // prevents ascenders and descenders from being cut off

// Z-Index
$z-index-background: -1;
$z-index-primary-nav: 5;
$z-index-datepicker: 10;
$z-index-skip-link: 20;
$z-index-loader: 30;
$z-index-toasts: 40;
$z-index-modal: 9999;
