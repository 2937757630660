.split {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.split > * + * {
  margin-left: remify(16);
}

.split--end {
  justify-content: flex-end;
}

.split--centered {
  align-items: center;
  > * {
    margin: 0 !important;
  }
}
