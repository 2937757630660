@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/settings/fonts';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/tools/mixins';

.payment-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -20px;
}

.payment-grid--grow-full {
  width: 100%;
}

.payment-grid--2 {
  > * {
    width: calc((100% / 2) - 20px);
  }
}

.payment-grid--3 {
  > * {
    width: calc((100% / 3) - 20px);
  }
}

.payment-grid__list-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  margin-bottom: 20px;
}

.payment-grid__value {
  color: palette(neutral, darker);
  text-transform: capitalize;
  font-size: remify(24);
  line-height: remify(29);
}

.card {
  background-color: palette(neutral, athens-gray);
  border-radius: 6px;
  display: flex;
  font-weight: font(weight, semibold);
  height: remify(75);
  padding: remify(0 20);
}
