@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/settings/fonts';
@import '../../../../assets/styles/tools/mixins';

$BOX_MARGIN: remify(16);
$COL_1_WIDTH: unquote('calc(25% - #{$BOX_MARGIN})');
$COL_2_WIDTH: unquote('calc(25% - #{$BOX_MARGIN})');
$COL_3_WIDTH: unquote('calc(50% - #{$BOX_MARGIN})');

.order-detail {
  @include vr(2.5);
  border-radius: remify(7);
  overflow: hidden;

  & > * {
    display: flex;
    flex-wrap: wrap;
    background-color: palette(neutral, white);
  }
}

.order-detail__hd {
  border-bottom: 1px solid palette(neutral, athens-gray);
  padding: remify(36);
}

.order-detail__bd {
  padding: remify(32);
}

.section {
  background-color: palette(neutral, athens-gray);
  border-radius: 6px;
  height: remify(75);
  margin-right: $BOX_MARGIN;
  padding-left: remify(18);

  &:nth-child(n + 4) {
    margin-top: remify(16);
  }
  &:nth-child(1),
  &:nth-child(4) {
    width: $COL_1_WIDTH;
  }
  &:nth-child(4) {
    width: 100%;
  }
  &:nth-child(2) {
    width: $COL_2_WIDTH;
  }
  &:nth-child(3) {
    width: $COL_3_WIDTH;
  }

  &__label {
    padding-top: remify(14);
    margin-bottom: remify(6);
    font-size: remify(14);
    font-weight: font(weight, semibold);
    color: palette(neutral, gray);
  }

  // raise specificity to override Value component styles
  & > &__value {
    font-size: remify(24);
    font-weight: font(weight, semibold);
    line-height: $base-line-height;
  }
}
