@import '../../assets/styles/settings/colors';
@import '../../assets/styles/settings/global';
@import '../../assets/styles/tools/functions';
@import '../../assets/styles/tools/mixins';

.date-filter {
  position: relative;
}

.date-filter__btn {
  background-color: palette(neutral, white);
  border: 1px solid palette(neutral, gray-chateau);
  color: palette(brand, primary);
  cursor: pointer;
  height: remify(48);
  padding: 0 remify(25);
  position: relative;
  text-align: left;
  width: auto;

  :global(.btn__text) {
    min-width: remify(82);
    padding-left: 0;
  }

  :global(.btn__icon) {
    color: palette(brand, primary);
    padding: remify(6 0 0 0);
    margin-left: remify(10);
  }

  &--is-picker-open,
  &--is-selected,
  &:hover,
  &:focus {
    background-color: palette(brand, primary);
    color: palette(neutral, white);

    :global(.btn__icon) {
      background-color: transparent;
      color: palette(neutral, white);
    }
  }

  // Make focus more visible since default focus-ring matches background-color
  [data-whatinput='keyboard'] &--is-selected:global(.focus-ring:focus),
  [data-whatinput='keyboard'] &--is-picker-open:global(.focus-ring:focus) {
    box-shadow: 0 0 5px 5px palette(brand, secondary);
  }
}

.date-filter__date-picker {
  margin-top: 10px;
  position: absolute;
  left: 0;

  @include bpLarge() {
    left: auto;
    right: 0;
  }
}
