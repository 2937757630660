@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/settings/fonts';
@import '../../../../assets/styles/tools/mixins';

.location-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: palette(neutral, white);
  border-radius: remify(7);
  margin: remify(15 0);
}

.editContainer {
  background-color: white;
  width: remify(160);
  border-radius: 14px 0 14px 14px;
  box-shadow: 0 2px 16px 9px rgba(0, 0, 0, 0.05);
  margin-left: auto;
  padding: remify(15 0);
  position: relative;
  bottom: 30px;
  right: 10px;
  cursor: pointer;
  &_link {
    display: flex;
    align-items: center;
    padding-left: remify(14);
  }
  &_text {
    font-size: 14px;
    font-weight: bold;
    padding: remify(8);
    color: #0375ba;
  }
  &_textDelete {
    font-size: 14px;
    font-weight: bold;
    padding: remify(8);
    color: palette(status, error);
  }
}

.suspendedBox {
  padding: remify(0 0 30 25);
  display: inline-flex;
  align-items: center;
  flex-basis: 100%;
  width: 0;
  &_text {
    padding-left: remify(8);
    font-weight: bold;
    color: palette(status, error);
  }
}

.error_icon {
  width: remify(16);
  height: remify(16);
}
