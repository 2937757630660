@import '../settings/colors';

$label-padding: 10px 20px;
$error-red: #d93425;

.form {
  display: flex;
  flex-direction: column;
}

.form__input-container {
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding: $label-padding;
  &:focus-within {
    border: 2px solid palette(brand, bright-blue);
  }
}

.form__select-container {
  position: relative;
  opacity: 1;
  transform: translateX(0) translateZ(1);
  transition: all 0.5s;
}

.form__select-container--hidden {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transform: translateX(50px);
}

.form__input-container--full {
  max-width: 100%;
}

.form__select-label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding: $label-padding;
  color: palette(neutral, dim-gray);
}

.form_title {
  padding-bottom: remify(18);
}

.form__label {
  font-size: 16px;
  margin-bottom: 5px;
  color: palette(neutral, dim-gray);
}

.form__input {
  font-size: 20px;
  background: transparent;
  border-radius: none;
  border: none;
  outline: none;
}
.form__checkbox-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.form__checkbox {
  margin-right: remify(9);
}

.form__error {
  background: $error-red;
  color: #fff;
  border: 1px solid $error-red;
  border-radius: 10px;
  margin: 30px 0;
  padding: 30px;
}

.errors__line {
  margin-bottom: 10px;
  font-weight: bold;
}

.form__error-highlight {
  border-left: 3px solid $error-red;
  box-shadow: 0px 0px 4px 0px rgba(255, 0, 0, 0.1);
  label {
    color: $error-red;
  }
}

.form__disable {
  &-fields {
    pointer-events: none;
    opacity: 0.5;
  }
  &-label {
    color: palette(neutral, gray-chateau);
  }
}

.form__select--multi-select__lock-in-btn {
  background: transparent;
  position: absolute;
  right: 21px;
  z-index: 99;
  height: 100%;
  border: 0;
  top: 0;
}

.form__select--not-to-be-displayed {
  display: none;
}

.form__select-container--multi-select {
  width: 100% !important;
}

.error-color {
  color: palette(status, error);
}

.top-helper-message {
  margin: 5px 0px 5px 5px;
}

.helper-message {
  margin: 5px 0px 0px 5px;
}
.helper-error-message {
  color: $error-red;
}

#create-typical-hours .form__select-wrapper {
  display: grid;
  grid-template-columns: 20% 35% 35%;
  column-gap: 2.5%;
  align-items: center;
  :nth-child(1) {
    grid-column-start: 1;
    grid-column-end: span 3;
    grid-row-start: 1;
  }
  :nth-child(1n) {
    .form__checkbox-label {
      font-size: 18px;
      font-weight: bold;
    }
  }
}
