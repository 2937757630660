@import '../../../../../../../assets/styles/settings/global';
@import '../../../../../../../assets/styles/settings/colors';
@import '../../../../../../../assets/styles/settings/fonts';
@import '../../../../../../../assets/styles/tools/functions';
@import '../../../../../../../assets/styles/tools/mixins';

.termContainer {
  margin-bottom: remify(25);
}

.termName {
  font-size: remify(14);
  font-weight: bold;
}

.termDescription {
  font-size: remify(14);
  text-align: left;
}
