@import '../../assets/styles/settings/global';
@import '../../assets/styles/tools/mixins';
@import '../../assets/styles/tools/functions';

.acc-overview {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;

  :global(.search__input) {
    border-width: remify(1);
    padding-top: remify(4);
  }
}

.acc-overview--split {
  background: transparent;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: remify(30);
}

.tableContainer {
  display: flex;
  position: relative;
}

@include bpLarge() {
  .acc-overview {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  .acc-overview--split {
    background: transparent;
    flex-direction: column;
    align-items: flex-start;
  }
}
