$NAV_PADDING_TOP: 20px;
$NAV_PADDING_SIDE: 35px;

[data-whatinput='keyboard'] .react-calendar-ping button:enabled:focus {
  box-shadow: 0 0 1px 1px palette(brand, primary);
}

.react-calendar-ping {
  button:enabled:focus {
    background-color: transparent;
  }

  &.react-calendar {
    border: 0;
    font-family: font(family, base);

    button:enabled:hover,
    button:enabled:active {
      background-color: transparent;
      box-shadow: 0 0 1px 1px palette(brand, primary);
    }
  }

  .react-calendar__month-view__days {
    margin: remify(0 3 3);
  }

  // CALENDAR DATE STYLES //

  .react-calendar__tile {
    padding: remify(4 0);
  }

  .react-calendar__tile > * {
    color: palette(neutral, nevada);
    // Ensure border-radius is circle for single-digit dates.
    display: inline-block;
    width: 30px;
    position: relative;
    height: 30px;
    padding-top: remify(5);
    z-index: 1;
  }

  .react-calendar__tile--active {
    background-color: transparent;
    box-shadow: none;
  }

  .react-calendar__tile--active > * {
    background-color: palette(brand, primary);
    border-radius: 50%;
    color: white;
  }

  .react-calendar__month-view__weekdays__weekday > * {
    color: black;
    font-size: remify(16);
    font-weight: font(weight, semibold);
    text-decoration: none;
  }

  // NAVIGATION BAR STYLES //

  .react-calendar__navigation {
    height: auto;

    & > * {
      margin-top: $NAV_PADDING_TOP;
    }

    button:enabled:focus,
    button[disabled] {
      background-color: transparent;
    }
  }

  .react-calendar__navigation__label {
    font-size: remify(18);
    font-weight: font(weight, bold);
  }

  // Hide prev2 and next2 buttons
  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__navigation__prev-button {
    margin-left: $NAV_PADDING_SIDE;
  }
  .react-calendar__navigation__next-button {
    margin-right: $NAV_PADDING_SIDE;
  }
  .react-calander__ping-highlight-range,
  .react-calendar__ping-highlight-start,
  .react-calendar__ping-highlight-end {
    position: relative;
    &::after {
      content: '';
      top: 12%;
      left: 0;
      height: 75%;
      width: 100%;
      background: rgba(3, 113, 181, 0.2);
      z-index: 0;
      position: absolute;
    }
  }

  .react-calendar__ping-highlight-start {
    &::after {
      left: 50%;
    }
  }

  .react-calendar__ping-highlight-end {
    &::after {
      left: auto;
      right: 50%;
    }
  }

  .react-calendar__tile--now {
    background: transparent;
    & > *::selection {
      background: inherit;
      color: inherit;
    }
  }
}
