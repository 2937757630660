//----------------------------------------------------------------------------
// Utilities: Focus
//----------------------------------------------------------------------------

// Replace outline with styled focus-ring on keyboard only
.focus-ring:focus {
  outline: 0;
}

[data-whatinput='keyboard'] .focus-ring:focus {
  box-shadow: 0 0 1px 1px palette(brand, primary);
}
