$grid-gap: 12px;

.invoice-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-right: -$grid-gap;
}

.invoice-cards > * {
  margin-right: $grid-gap;
  margin-top: $grid-gap;
  max-width: 100%;
  width: 100%;

  @include bpMedium() {
    max-width: calc((100% / 2) - #{$grid-gap});
  }
  @include bpLarge() {
    max-width: calc((100% / 3) - #{$grid-gap});
  }
  @include bpXLarge() {
    max-width: calc((100% / 4) - #{$grid-gap});
  }
}
