@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/settings/fonts';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/tools/mixins';

.link-card {
  border: 1px solid palette(neutral, gray-chateau);
  border-radius: 4px;
  color: palette(neutral, shark);
  display: inline-block;
  font-weight: font(weight, semibold);
  overflow: hidden;
  padding: remify(20);
  width: 100%;
  min-height: remify(66);
  cursor: pointer;
  @include vr(1);

  &:hover,
  &:active,
  &:focus {
    background-color: palette(neutral, athens-gray);
    border: 1px solid palette(brand, primary);
  }
}

.link-card__inner {
  align-items: center;
  display: flex;
  justify-content: space-between;

  :global(.icon) {
    color: palette(brand, primary);
    flex-shrink: 0;
    margin-left: remify(10);
  }
}
