@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/settings/fonts';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/tools/mixins';

.accountsContainer {
  background: transparent;
  max-height: remify(500);
  overflow-y: scroll;
}

.buttonContainer {
  margin-top: remify(20);
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  min-width: 150px;
  margin-left: auto;

  &__alignment {
    margin-right: remify(10);
  }
}

.cancelButton {
  border: none;
  font-weight: bold;
}

.userName {
  margin-bottom: remify(10);
}
