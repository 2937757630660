@import '../../assets/styles/settings/global';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/settings/fonts';
@import '../../assets/styles/tools/functions';
@import '../../assets/styles/tools/mixins';

.wrapper {
  min-height: remify(100);
  position: relative;
}

.small-wrapper {
  min-height: remify(20);
  position: relative;
}

:global(.full-page) {
  height: 100vh;
  margin-left: -(2.5rem);
}

:global(.accounts-loader) {
  min-height: remify(200);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  .loader-container {
    background-color: rgba(239, 240, 244, 0.85); // athens-gray
    justify-content: flex-start;
    padding-top: remify(80);
  }
}

:global(.search-loader) {
  margin-top: remify(200);
  margin-left: auto;
  margin-right: auto;
}

.loader-container {
  align-items: center;
  background-color: transparent;
  bottom: 0;
  color: palette(neutral, white);
  content: '';
  cursor: wait;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: $z-index-loader;
  text-align: center;
}
.loaderButton {
  margin: 0 auto;
}

.inverted {
  color: palette(brand, secondary);
}

.loader-container__HeaderMessage {
  color: palette(neutral, shark);
  display: block;
  font-size: remify(18);
  font-weight: font(weight, semibold);
  margin-top: remify(10);
  margin-bottom: remify(10);
}

.loader-container__ErrorMessage {
  color: palette(neutral, shark);
  display: block;
  font-size: remify(18);
  margin-top: remify(10);
  margin-bottom: remify(10);
}

// Adapted from https://loading.io/css/
.lds-roller {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 30px 30px;
}
.lds-roller div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: currentColor;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 47px;
  left: 47px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 51px;
  left: 42px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 53px;
  left: 36px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 54px;
  left: 30px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 53px;
  left: 24px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 51px;
  left: 18px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 47px;
  left: 13px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 42px;
  left: 9px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: palette(brand, secondary);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.spinner {
  width: 200px;
  text-align: center;
}

.spinner > div {
  width: 15px;
  height: 15px;
  margin: 0 5px;
  background-color: palette(brand, primary);

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
