@import '../../assets/styles/settings/global';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/settings/fonts';
@import '../../assets/styles/tools/functions';
@import '../../assets/styles/tools/mixins';

.error5 {
  @include card(remify(32));
}

.error5__grid {
  display: grid;
  grid-template-columns: remify(40) auto;
  column-gap: remify(25);
}

.error5__icon {
  // background: red;
}

.error__messageArea {
  background: red;
}

.error5__buttonContainer {
  display: flex;
  > * {
    margin-right: 30px;
  }
}
