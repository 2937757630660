@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/tools/mixins';

.status-filters {
  display: flex;
  > li {
    margin-left: 35px;
    padding-bottom: 5px;
    border-bottom: 3px solid transparent;
    &:first-child {
      margin-left: 0;
    }
    &.active {
      border-color: palette(brand, primary);
    }
  }
  button {
    color: palette(neutral, gray);
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    font-weight: 600;
  }
}
