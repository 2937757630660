@import '../../assets/styles/settings/global';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/settings/fonts';
@import '../../assets/styles/tools/functions';
@import '../../assets/styles/tools/mixins';

.payment-container {
  @include card(remify(32));
}

.payment-container__prepaid-amount {
  h4 {
    margin: remify(25) 0 remify(35) 0;
  }
}
