@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/settings/fonts';
@import '../../../../assets/styles/tools/mixins';

.locationSection {
  display: inline-flex;
  align-items: baseline;
  padding-top: remify(20);
  &_title {
    color: palette(neutral, shark);
    font-size: 18px;
    font-weight: 600;
    padding-right: remify(8);
  }
  &_desc {
    color: palette(neutral, gray);
    font-size: 14px;
    font-weight: 600;
  }
}
