@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/settings/fonts';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/tools/mixins';

.buttonContainer {
  margin-top: remify(20);
  display: flex;
  align-items: center;
  justify-content: center;
  &_cancel {
    min-width: remify(210);
  }
  &_next {
    min-width: remify(210);
    margin-left: auto;
  }
}
