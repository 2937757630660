@import '../../assets/styles/settings/global';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/settings/fonts';
@import '../../assets/styles/tools/functions';
@import '../../assets/styles/tools/mixins';
.PDFNavContainer {
  position: relative;
  background: palette(brand, primary);
  display: flex;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 1200px;
  padding: 10px 0;

  button:nth-child(2) {
    margin-left: remify(100);
  }

  > * {
    margin-left: remify(25);
  }
}

.videoNavContainer {
  position: relative;
  background: palette(brand, primary);
  display: flex;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: remify(1200);
  padding: 10px 0;
  min-height: remify(68);
}

.PDFNavContainer__CloseBtn {
  position: absolute;
  left: remify(0);
  top: remify(10);
  padding: 0 25px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: palette(neutral, white);
  font-size: remify(48);
  line-height: remify(48);

  &--on-right {
    left: auto;
    right: remify(20);
  }
}

.PDFNav__btn {
  &:hover {
    background: palette(neutral, iron);
    color: palette(brand, primary);
  }
}
