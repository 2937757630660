@import '../../../../assets/styles/tools/mixins';
@import '../../../../assets/styles/tools/functions';

.tab-order {
  @include userInitials(88px, 40px);
  font-size: 15px;
  line-height: 0;
  margin: 0.25rem;
  height: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  background-color: #000000;
}
.disabled {
  background-color: grey;
}
