@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/tools/mixins';
@import '../../../../assets/styles/settings/fonts';

.order-review__pmbtn {
  padding: remify(5);
  background: palette(brand, primary);
  border-radius: 100%;
  color: palette(neutral, white);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: remify(1) solid palette(brand, primary);
  transition: all 255ms cubic-bezier(0.29, 0.19, 0.405, 1);
  margin: 0 remify(20);

  &:hover,
  &:focus {
    color: palette(brand, primary);
    background: palette(neutral, white);
  }

  &--disabled {
    background: palette(neutral, shark);
    color: palette(neutral, white);
    border: remify(1) solid palette(neutral, shark);

    &:hover,
    &:focus {
      background: palette(neutral, shark);
      color: palette(neutral, white);
    }
  }
}

// .order-review__pmbtn--disabled {
//   background: palette(neutral, shark);
// }

.order-review__qty {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: remify(180);
}

.order-review__qty-input,
.order-review__qty-input--isChanging {
  background: transparent;
  border: 1px solid palette(neutral, gray-chateau);
  border-radius: 3px;
}
.order-review__qty-input--isChanging {
  max-width: 40px;
  border: 1px solid palette(brand, primary);
  outline-color: palette(brand, primary);
}

.order-review__description {
  margin: remify(15);
}

.order-review__edit-container {
  margin-top: remify(20);
}
