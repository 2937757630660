@import '../settings/_global';
@import '../settings/_colors';
@import '../settings/_fonts';

//----------------------------------------------------------------------------
// Tools: Mixins
//----------------------------------------------------------------------------

// Accessibly hide elements
@mixin isVisuallyHidden() {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// Breakpoints
//
// Usage:
// @include bpMedium() {
//   .foo {
//     display: none;
//   }
// }
@mixin bpMedium() {
  @media only screen and (min-width: $viewport-width-md) {
      @content;
  }
}

@mixin bpLarge() {
  @media only screen and (min-width: $viewport-width-lg) {
      @content;
  }
}

@mixin bpXLarge() {
  @media only screen and (min-width: $viewport-width-xl) {
      @content;
  }
}

@mixin vr($size) {
  margin-bottom: remify($base-vertical-rhythm * $size);
}

@mixin card($padding) {
  background-color: palette(neutral, white);
  border-radius: 7px;
  padding: $padding;
}

@mixin userInitials($height, $fontSize) {
  border-radius: 50%;
  background-color: palette(brand, primary);
  color: palette(neutral, white);
  flex-shrink: 0;
  font-size: $fontSize;
  font-weight: font(weight, bold);
  height: $height;
  letter-spacing: 1px;
  line-height: $height;
  text-align: center;
  text-transform: capitalize;
  vertical-align: middle;
  width: $height;
}

@mixin btnLink() {
  appearance: none;
  background-color: transparent;
  border: none;
  color: palette(brand, secondary);
  cursor: pointer;
}

@mixin wrapper() {
  max-width: $viewport-width-xl;
  padding-bottom: remify(48);
  padding-top: remify(48);
  padding-left: remify(32);
  padding-right: remify(32);

  @include bpLarge() {
    padding-left: remify(48);
    padding-right: remify(48);
  }

  @include bpXLarge() {
    padding-left: remify(80);
    padding-right: remify(80);
  }
}

@mixin truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin breakContainer() {
  width: 1440px;
  position: relative;
  right: 50%;
  left: 50%;
  margin-left: -720px;
}

@mixin defaultPopper() {
  background: palette(neutral, white);
  padding: remify(20);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.1);
  min-width: remify(250);
  border-radius: remify(7);
  border-top-right-radius: remify(2);
}
@mixin orderStatus() {
  align-items: center;
  border: 2px solid transparent;
  border-radius: 4px;
  display: inline-flex;
  font-size: remify(11);
  height: remify(24);
  justify-content: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: remify(0 5);
  min-width: remify(85);

  @include bpXLarge() {
    font-size: remify(13);
  }

  &[data-orderstatus='SHIPPED'],
  &[data-orderstatus='shipped'],
  &[data-orderstatus='Shipped'] {
    color: palette(status, successText);
    border-color: palette(status, success);
  }
  &[data-orderstatus='boxed'],
  &[data-orderstatus='BOXED'],
  &[data-orderstatus='Boxed'] {
    color: palette(status, progress);
    border-color: palette(status, progress);
  }
  &[data-orderstatus='production'],
  &[data-orderstatus='PRODUCTION'],
  &[data-orderstatus='Production'] {
    color: palette(status, warning);
    border-color: palette(status, warning);
  }
  &[data-orderstatus='mixed'],
  &[data-orderstatus='MIXED'],
  &[data-orderstatus='Mixed'] {
    color: palette(status, completeText);
    border-color: palette(status, completeText);
  }
  &[data-orderstatus='cancelled'],
  &[data-orderstatus='CANCELLED'],
  &[data-orderstatus='Cancelled'] {
    color: palette(status, error);
    border-color: palette(status, error);
  }
  &[data-orderstatus='On Order'],
  &[data-orderstatus='ON ORDER'],
  &[data-orderstatus='on order'] {
    color: palette(status, ready);
    border-color: palette(status, ready);
  }
}

@mixin transactionStatusColors {
  &[data-transactionstatus='open'],
  &[data-transactionstatus='Open'],
  &[ata-transactionstatus='OPEN'] {
    color: palette(status, readyText);
    border-color: palette(status, ready);
  }
  &[data-transactionstatus='current'],
  &[data-transactionstatus='Current'],
  &[data-transactionstatus='CURRENT'] {
    color: palette(status, warningText);
    border-color: palette(status, warning);
  }
  &[data-transactionstatus='closed'],
  &[data-transactionstatus='Closed'],
  &[data-transactionstatus='CLOSED'] {
    color: palette(status, completeText);
    border-color: palette(status, complete);
  }
  &[data-transactionstatus='past due'],
  &[data-transactionstatus='Past due'],
  &[data-transactionstatus='PAST DUE'] {
    color: palette(status, error);
    border-color: palette(status, error);
  }
  &[data-transactionstatus='open'],
  &[data-transactionstatus='Open'],
  &[data-transactionstatus='OPEN'] {
    color: palette(status, readyText);
    border-color: palette(status, ready);
  }
}

@mixin transactionStatus {
  align-items: center;
  border: 2px solid transparent;
  border-radius: 4px;
  display: inline-flex;
  font-size: remify(11);
  height: remify(24);
  justify-content: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: remify(0 5);
  min-width: remify(80);

  @include bpXLarge() {
    font-size: remify(13);
    width: remify(90);
  }

  // TODO: see if we can delete the lower case versions
  @include transactionStatusColors
}