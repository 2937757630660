.refresh-title__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.refresh-title__container-buttonPadding {
  padding-bottom: 20px;
  padding-left: 20px;
}
