@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/tools/mixins';

.primaryNavHeader {
  border: 0;
  border-bottom: 1px solid palette(neutral, athens-gray);
  cursor: pointer;
  margin: 1px;
  min-height: remify(100);
}

.primaryNavHeader img {
  margin: auto;
}

.primaryNavHeader svg {
  color: palette(brand, primary);
  position: absolute;
  right: 4px;
  top: remify(37);
  transform: rotate(270deg) scale(0.5, 0.5);
}

:global(.primaryNav.is-expanded) .primaryNavHeader svg {
  left: 6px;
  transform: rotate(90deg) scale(0.5, 0.5);
}

/* Hide small logo, show */
.primaryNavHeader-logo_small {
  display: none;
}
:global(.primaryNav.is-collapsed) .primaryNavHeader-logo_small {
  display: block;
}
:global(.primaryNav.is-collapsed) .primaryNavHeader-logo_big {
  display: none;
}

.skipLink:not(:focus) {
  @include isVisuallyHidden;
  z-index: $z-index-skip-link;
}
