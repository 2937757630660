@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/settings/fonts';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/tools/mixins';

.fittingFormContainer {
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 50px;
  &_label {
    display: flex;
    & input {
      width: remify(16);
      height: remify(16);
      margin-right: remify(9);
    }
  }
}

.buttonContainer {
  margin-top: remify(20);
  display: flex;
  align-items: center;
  justify-content: center;
  &_cancel {
    min-width: remify(210);
  }
  &_next {
    min-width: remify(210);
    margin-left: auto;
  }
}
.radio-button-label {
  font-size: 16px;
  display: inline;
  text-align: start;
  width: 100%;
  padding-left: remify(10);
}
.inline {
  display: inline;
}
.radio-container {
  margin-right: 0px;
}
.radio-padding {
  padding-top: remify(20);
  padding-bottom: remify(50);
}
