@import '../../assets/styles/settings/global';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/tools/functions';
@import '../../assets/styles/tools/mixins';

.seconds {
  color: red;
  font-size: remify(20);
  font-weight: font(weight, bold);
}
