@import '../../assets/styles/settings/global';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/settings/fonts';
@import '../../assets/styles/tools/functions';
@import '../../assets/styles/tools/mixins';

.maintenance__wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.box__bd {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header {
  font-size: remify(32);
  font-weight: font(weight, bold);
  margin-bottom: remify(40);
  text-align: center;
}

.body {
  text-align: center;
}
