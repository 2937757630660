@import '../../assets/styles/tools/functions';
@import '../../assets/styles/settings/global';
@import '../../assets/styles/settings/colors';

.dashboard__hero {
  background: linear-gradient(palette(brand, endeavour), palette(brand, bahama-blue));
  position: relative;

  h1 {
    color: white;
    text-transform: capitalize;
  }
}

.dashboard__search {
  position: absolute;
  left: remify(80);
  bottom: 0;
  transform: translateY(50%);

  @media (max-width: $viewport-width-lg) {
    left: remify(20);
  }
}

.link,
.link svg {
  color: palette(brand, primary);
}

.link svg {
  margin-left: remify(4);
  height: remify(14);
  width: remify(12);
  position: relative;
  top: remify(3);
}
