@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/settings/fonts';
@import '../../../../assets/styles/tools/mixins';

$COL_1_WIDTH--LG: 25%;
$COL_2_WIDTH--LG: 25%;
$COL_3_WIDTH--LG: 8%;
$COL_4_WIDTH--LG: 8%;
$COL_5_WIDTH--LG: 8%;
$COL_6_WIDTH--LG: 16%;
$COL_7_WIDTH--LG: 10%;
$COL_1_WIDTH: 33%;
$COL_2_WIDTH: 33%;
$COL_3_WIDTH: 33%;
$COL_4_WIDTH: 33%;
$COL_5_WIDTH: 33%;
$COL_6_WIDTH: 33%;

.section {
  font-weight: font(weight, semibold);
  padding: remify(25 0 30 25);

  &:nth-child(1) {
    width: $COL_1_WIDTH;
    @include bpLarge() {
      width: $COL_1_WIDTH--LG;
    }
  }
  &:nth-child(2) {
    width: $COL_2_WIDTH;
    @include bpLarge() {
      width: $COL_2_WIDTH--LG;
    }
  }
  &:nth-child(3) {
    width: $COL_3_WIDTH;
    @include bpLarge() {
      width: $COL_3_WIDTH--LG;
    }
  }
  &:nth-child(4) {
    width: $COL_4_WIDTH;
    @include bpLarge() {
      width: $COL_4_WIDTH--LG;
    }
  }
  &:nth-child(5) {
    width: $COL_5_WIDTH;
    @include bpLarge() {
      width: $COL_5_WIDTH--LG;
    }
  }
  &:nth-child(6) {
    width: $COL_6_WIDTH;
    @include bpLarge() {
      width: $COL_6_WIDTH--LG;
    }
  }

  &:last-child {
    width: $COL_6_WIDTH;
    padding: remify(25 25 30 0);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include bpLarge() {
      width: $COL_7_WIDTH--LG;
    }
  }

  &__label {
    display: flex;
    margin-bottom: remify(10);
    font-size: remify(14);
    color: palette(neutral, oslo-gray);
  }
}

@media only screen and (max-width: $viewport-width-lg) {
  .column_hidden {
    display: none;
  }
}

.edit {
  cursor: pointer;
}

.active {
  color: palette(status, successText);
}

.inactive {
  color: palette(neutral, oslo-gray);
}

.suspended {
  color: palette(status, error);
}
