@import '../../assets/styles/settings/global';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/settings/fonts';
@import '../../assets/styles/tools/functions';
@import '../../assets/styles/tools/mixins';

.locationTab {
  
  &_tabList {
    background-color: palette(brand, secondary);
    color: palette(neutral, white);
    padding: remify(18 80);
  }
  &_tab {
    display: inline-block;
    padding: remify(14);
    cursor: pointer;
    border-radius: remify(22);
    text-align: center;
    min-width: remify(120);
    
    &_selected {
      color: palette(neutral, black);
    }
  }
  &_panel {
    display: block;
    background-color: palette(neutral, athens-gray);
    padding-left: remify(80);
    padding-right: remify(80);
  }
}
