@import '../../assets/styles/settings/global';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/tools/functions';
@import '../../assets/styles/tools/mixins';

/* Must be global to push over main content */
.primaryNav {
  background-color: palette(neutral, white);
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  transition: width $transition-timing ease-in-out;
  width: remify($primary-nav-collapsed-width);
  z-index: $z-index-primary-nav;
}

.primaryNav > * {
  background-color: palette(neutral, white);
}

.primaryNav.is-expanded {
  transition: width $transition-timing ease-in-out;
  width: remify($primary-nav-expanded-width);
}
