@import '../../assets/styles/settings/global';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/tools/mixins';
@import '../../assets/styles/settings/fonts';
@import '../../assets/styles/tools/functions';

.date-picker {
  border-radius: 10px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  background-color: palette(neutral, athens-gray);
  z-index: $z-index-datepicker;

  display: flex;
  flex-wrap: wrap;
  width: 350px;
  & > :global(.react-calendar) + :global(.react-calendar) {
    border-top: 1px solid palette(neutral, gray-chateau);
  }
  @include bpLarge() {
    width: 700px;
    & > :global(.react-calendar) + :global(.react-calendar) {
      border-top: none;
      border-left: 1px solid palette(neutral, gray-chateau);
    }
  }
}

.date-picker__btn-container {
  margin-top: 10px;
  margin-left: auto;
}

.date-picker__footer {
  width: 100%;
  display: flex;
  align-items: center;
  padding: remify(10) remify(15);
}

.dateHeader__startEnd {
  font-weight: normal;
  color: palette(neutral, oslo-gray);
}

.dateHeader__monthYear {
  font-weight: normal;
  margin-top: 10px;
}
