$btn-min-size: $base-font-size * 3;
$btn-border-radius: calc($btn-min-size / 2);

.btn {
  align-items: center;
  background-color: palette(brand, secondary);
  border: 1px solid palette(brand, secondary);
  cursor: pointer;
  border-radius: remify($btn-border-radius);
  color: palette(neutral, white);
  display: flex;
  font-size: remify($base-font-size);
  height: remify($btn-min-size);
  justify-content: center;
  min-width: remify($btn-min-size);
}

.btn--loading {
  $btn-min-size: $base-font-size * 3;
  $btn-border-radius: calc($btn-min-size / 2);
  align-items: center;
  margin-right: remify(20);
  display: flex;
  height: remify($btn-min-size);
  justify-content: center;
  min-width: remify($btn-min-size);
}

.btn--loading__text {
  margin-left: remify(50);
  color: palette(brand, primary);
}

.btn--link {
  background: transparent;
  border: none;
  color: palette(brand, primary);
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.btn:focus,
.btn:hover {
  background-color: palette(neutral, white);
  color: palette(brand, secondary);
  transition: background-color $transition-timing ease-in-out, color $transition-timing ease-in-out;
}

.btn--inverted {
  background-color: palette(neutral, white);
  color: palette(brand, primary);

  &:hover {
    background-color: palette(brand, primary);
    color: palette(neutral, white);
  }
}

.btn--disabled {
  @extend .btn;

  cursor: auto;
  border: 1px solid palette(neutral, gray);
  background-color: palette(neutral, gray);
  color: palette(neutral, white);

  &:hover,
  &:focus {
    background-color: palette(neutral, gray);
    color: palette(neutral, white);
  }
}

.btn__text-only {
  padding-left: remify(24);
  padding-right: remify(24);
}

.btn__text {
  padding-left: remify(20);
}

// If button is only an icon with no text
.btn__icon {
  color: palette(neutral, white);
  padding: remify(0 16);
}

.btn__icon--disabled {
  color: palette(neutral, white);
  padding: remify(0 16);
}

// add this to the svg icon
.btn__svg-icon {
  margin-left: remify(20);
}

// Useful for making buttons that look like links
.btn--no-style {
  background: transparent;
  min-width: auto;
  padding: 0;
  border: 0;
  color: palette(brand, primary);
  cursor: pointer;
}

.btn--red {
  color: palette(status, error);
  border: 1px solid palette(status, error);
  background: transparent;

  &:hover {
    background: palette(status, error);
    color: palette(neutral, white);
  }
}

.btn--red--inverted {
  background: palette(status, error);
  border: 1px solid palette(status, error);
  color: palette(neutral, white);

  &:hover {
    color: palette(status, error);
    background: transparent;
  }
}
.btn--red-text {
  color: palette(status, error);
}

.btn--bold {
  font-weight: bold;
}

.btn:focus .btn__icon,
.btn:hover .btn__icon {
  color: palette(brand, secondary);
  transition: background-color $transition-timing ease-in-out, color $transition-timing ease-in-out;
}

.btn--form-button {
  width: calc(50% - 20px);
  font-size: 20px;
  font-weight: 600;
  height: remify($btn-min-size * 1.3);
  border-radius: 50000px;
}

.btn--delete-user {
  border: 1.5px solid palette(status, error);
  background: palette(neutral, white);
  color: palette(status, error);

  &:hover,
  &:focus {
    background: palette(status, error);
    color: palette(neutral, white);
  }
}
.radio-btn-container {
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
  align-items: flex-start;
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: auto;
  }
}
.radio-btn {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 2px solid palette(neutral, gray-chateau);
  background: transparent;
  &::after {
    content: '';
    position: absolute;
    display: flex;
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    margin-left: -5px;
    margin-top: -5px;
    transform: scale(0);
    transform-origin: center;
    background: white;
    border-radius: 100%;
    border: 0px solid transparent;
    transition: all 255ms cubic-bezier(0.29, 0.19, 0.405, 1);
  }

  &--selected {
    background: palette(brand, primary);
    border: palette(brand, primary);
    &::after {
      transform: scale(1);
      background: white;
    }
  }
}
