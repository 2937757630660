@import '../../../../../../../assets/styles/settings/global';
@import '../../../../../../../assets/styles/settings/colors';
@import '../../../../../../../assets/styles/settings/fonts';
@import '../../../../../../../assets/styles/tools/functions';
@import '../../../../../../../assets/styles/tools/mixins';

.cardContainer {
  background: transparent;
  display: flex;
  flex-direction: column;
  @include card(remify(30 30));
  width: 100%;
  margin-bottom: 12px;
  position: relative;
  align-items: normal;
  justify-content: space-between;
  row-gap: remify(10);
  border-radius: 5px;
  box-shadow: 0 2px 16px 9px rgba(0, 0, 0, 0.05);

  > * {
    margin-right: remify(20);
  }

  & .protected {
    background-color: burlywood;
    border-color: black;
  }
}
.protectedCategory {
  clear: left;
  float: right;
  top: remify(15);
  position: absolute;
  right: remify(0);
}
.icon{
  color: palette(neutral,gray);
}

.cardTitle {
  font-size: 18px;
  font-weight: bold;
}

.cardLabel {
  font-weight: bold;
}

.cardCheckbox {
  display: flex;
}
.disabled {
  cursor: not-allowed;
}
.enabled {
  cursor: pointer;
}
input[disabled] {
  background-color: white;
  color: grey;
  cursor: not-allowed;
}
