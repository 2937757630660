@import '../../assets/styles/settings/global';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/settings/fonts';
@import '../../assets/styles/tools/functions';
@import '../../assets/styles/tools/mixins';

.arrow-link,
.arrow-link svg,
.arrow-link button {
  color: palette(brand, primary);
  font-weight: font(weight, semibold);
  font-size: remify(16);
  line-height: remify(20);
}

:global(.arrow-link--inverse),
:global(.arrow-link--inverse svg),
:global(.arrow-link--inverse a),
:global(.arrow-link--inverse button) {
  color: palette(neutral, white);
}

.back svg {
  margin-right: remify(12);
  transform: rotate(90deg) scale(1.2, 1.4);
}
.forward svg {
  margin-left: remify(12);
  transform: rotate(-90deg) scale(1.2, 1.4);
}
