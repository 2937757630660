.tiles {
  display: flex;
  flex-direction: column;
}

.tiles--vr2 {
  > * + * {
    margin-top: remify(20);
  }
}

.tiles--vr5 {
  > * + * {
    margin-top: remify(50);
  }
}

.tiles__tile__card {
  flex-grow: 1;
  @include card(remify(40 36));
}

@include bpMedium() {
  [class*='tiles--vr'] {
    > * + * {
      margin-top: 0;
    }
  }
  .tiles--split {
    flex-direction: row;
    justify-content: space-between;

    .tiles__tile {
      display: block;
      width: calc(50% - 10px);
    }

    .tiles__tile--has-card {
      display: flex;
      flex-direction: column;
    }
  }
}
