@import '../../assets/styles/settings/global';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/settings/fonts';
@import '../../assets/styles/tools/functions';
@import '../../assets/styles/tools/mixins';

.switchCheckbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switchLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: remify(60);
  height: remify(30);
  background: palette(neutral, gray-chateau);
  border-radius: remify(100);
  position: relative;
  transition: background-color 0.1s;
  &:before {
    position: absolute;
    width: remify(26);
    height: remify(26);
    border-radius: remify(45);
    transition: 0.1s;
    background: palette(neutral, white);
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }
  &:checked {
    width: remify(60);
    height: remify(30);
  }
}

.switchContainer {
  display: inline-flex;
}

.switchButton {
  position: absolute;
  left: 2px;
  width: remify(26);
  height: remify(26);
  border-radius: 45px;
  transition: 0.1s;
  background: palette(neutral, white);
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.switchCheckbox:checked {
  & + .switchLabel {
    background: palette(status, successText);
    .switchButton {
      left: calc(100% - 2px);
      transform: translateX(-100%);
    }
  }
}

.draftContainer {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 8px 35px;
  border: 1px solid #c0c0c0;
  border-radius: remify(22);
  background-color: palette(neutral, white);
  cursor: pointer;
  &_text {
    font-weight: bold;
    font-size: 18px;
    padding-right: 8px;
  }
}

.draft_modal {
  position: absolute;
  top: remify(290);
  right: remify(80);
  background-color: white;
  width: remify(310);
  border-radius: 14px 0 14px 14px;
  box-shadow: 0 2px 16px 9px rgba(0, 0, 0, 0.05);
  margin-left: auto;
  padding: remify(15 0);

  &_text {
    padding: remify(24);
  }
  &_bold {
    font-weight: bold;
  }
  &_close {
    position: absolute;
    right: remify(0);
    top: remify(2);
    padding: 0 30px;
    border: none;
    cursor: pointer;
    background-color: transparent;
    color: palette(brand, primary);
    font-size: remify(40);
  }
}
