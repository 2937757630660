@import '../../../assets/styles/settings/colors';
@import '../../../assets/styles/settings/global';
@import '../../../assets/styles/tools/functions';
@import '../../../assets/styles/tools/mixins';
@import '../../../assets/styles/settings/fonts';

.search {
  position: relative;
  width: remify(400);
}

.search__input {
  border: 1px solid palette(neutral, gray-chateau);
  border-radius: remify(34);
  color: palette(neutral, shark);
  font-family: font(family, base);
  font-size: remify(18);
  font-weight: font(weight, semibold);
  height: remify(55);
  padding-left: remify(28);
  padding-top: remify(4);
  width: 100%;

  // Added specificity to override reset styles
  &[type='search'] {
    box-sizing: border-box;
  }
}

.search__input::placeholder {
  color: palette(neutral, gray-chateau);
  font-size: remify(18);
  font-weight: font(weight, semibold);
}

.search__button {
  color: palette(neutral, white);
  cursor: pointer;
  height: remify(39);
  // must be min-width to override btn class
  min-width: remify(39);
  padding: 0;
  position: absolute;
  right: remify(10);
  top: remify(8);
}
