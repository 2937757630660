@import '../../assets/styles/tools/functions';
@import '../../assets/styles/tools/mixins';
@import '../../assets/styles/settings/global';

.search-results {
  position: relative;
}

.search-results__hd {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(#0254a2, #015b93);
  height: remify(102);
  z-index: $z-index-background;

  @media (min-width: $viewport-width-lg) {
    height: remify(130);
  }
}

.search-results__bd {
  @include wrapper();
}
