@import '../../assets/styles/settings/global';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/tools/functions';
@import '../../assets/styles/tools/mixins';

.order-detail-page__hd {
  display: flex;

  & > * + * {
    margin-left: remify(18);
    margin-top: remify(11);
  }
}
