@import '../../assets/styles/settings/colors';
@import '../../assets/styles/tools/functions';
@import '../../assets/styles/settings/global';
@import '../../assets/styles/tools/mixins';

.inline {
  display: inline;
}
.inline-bold {
  display: inline;
  font-weight: bold;
}
.left-align {
  text-align: left;
}
.center-align {
  text-align: center;
}
.left-padding {
  padding-left: remify(20);
}
.right-padding {
  padding-right: remify(20);
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
}
.grey {
  color: palette(neutral, oslo-gray);
}
.tag-lookup-table__card {
  background-color: palette(neutral, white);
  border-radius: 7px;
  padding: remify(15 0);
}
.search-details-container {
  padding-bottom: remify(20);
}
.error-message {
  padding-bottom: remify(35);
}
