@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/settings/fonts';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/tools/mixins';

$user-list-table-border-radius: 7px;

.user-list-table {
  border-collapse: separate;
  border-spacing: remify(0 8);
  padding-bottom: remify(20);
  width: 100%;
}

.user-list-table__row--hd .user-list-table__row__cell {
  &:first-child {
    padding-left: remify(18);
    width: remify(120);
  }

  &:last-child {
    padding-right: remify(18);
  }
}

.user-list-table__row--bd .user-list-table__row__cell {
  font-weight: font(weight, semibold);
  font-size: remify(18);
  line-height: $base-line-height;
  padding-top: remify(19);
  padding-bottom: remify(19);
  vertical-align: middle;

  &:first-child {
    border-top-left-radius: $user-list-table-border-radius;
    border-bottom-left-radius: $user-list-table-border-radius;
    padding-left: remify(18);
  }

  &:last-child {
    border-top-right-radius: $user-list-table-border-radius;
    border-bottom-right-radius: $user-list-table-border-radius;
    padding-right: remify(18);
  }
}

.filter-btn {
  padding-right: remify(40);
}

.user-roles-btn {
  @include btnLink();
  margin-right: remify(20);
}
