.hdg {
  color: palette(neutral, mine-shaft);
  line-height: $base-line-height;
  @include vr(3);
}

.hdg--1 {
  font-size: remify(42);
}

.hdg--2 {
  font-size: remify(36);
}

.hdg--3 {
  font-size: remify(24);
  font-weight: font(weight, bold);
}

.hdg--4 {
  font-size: remify(20);
  font-weight: font(weight, semibold);
  margin: remify(0 0 4 0);
}

.hdg--5 {
  font-size: remify(16);
  font-weight: font(weight, semibold);
  margin: 0;
}

.hdg--miniPadding {
  @include vr(1);
}

.hdg--thin {
  font-weight: 300;
}

.hdg--no-margin-bottom {
  margin-bottom: 0;
}
