@import '../../assets/styles/settings/global';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/settings/fonts';
@import '../../assets/styles/tools/functions';
@import '../../assets/styles/tools/mixins';

.pagination {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: remify(16);
  width: 100%;
}

.pagination__count {
  color: palette(neutral, shark);
  margin-left: 3%;
}

.pagination__count__hidden {
  display: none;
}

.pagination__nav {
  padding: 0;
  display: flex;
}

.pagination__nav__btn[type='button'] {
  background-color: palette(neutral, white);
  border: 0;
  border-radius: 3px;
  color: palette(brand, primary);
  cursor: pointer;
  display: inline-block;
  font-size: remify(14);
  font-weight: font(weight, semibold);
  height: remify(33);
  margin: 0 2px;
  padding: 0;
  text-align: center;
  width: remify(35);

  &:hover {
    background-color: palette(neutral, iron);
  }

  &[aria-current='page'] {
    background-color: palette(brand, primary);
    color: palette(neutral, white);
  }

  .icon {
    color: palette(brand, primary);
    width: remify(17);
  }

  &:disabled {
    &:hover {
      background-color: palette(neutral, white);
    }
  }
}
