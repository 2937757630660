.react-tabs {
  -webkit-tap-highlight-color: transparent;

  &__tab-list {
    // border-bottom: 1px solid #fff;
    margin: 0;
    padding: 0;
  }

  &__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 1.5rem;
    cursor: pointer;
    font-weight: 600;

    &--selected {
      background: #fff;
      border-color: #fff;
      color: black;
      border-radius: 5px 5px 0 0;
    }

    &--disabled {
      color: GrayText;
      cursor: default;
    }

    &:focus {
      box-shadow: 0 0 5px#aaa;
      border-color: #aaa;
      outline: none;

      &:after {
        content: '';
        position: absolute;
        height: 5px;
        left: -4px;
        right: -4px;
        bottom: -5px;
        background: #fff;
      }
    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;
      background-color: white;
      padding: 35px 35px 15px 35px;
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      overflow: hidden;
    }
  }
}
