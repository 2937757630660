@import '../../assets/styles/settings/global';
@import '../../assets/styles/tools/functions';

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: remify(16);
  position: fixed;
  right: 0;
  top: 0;
  z-index: $z-index-toasts;
}
