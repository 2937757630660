.statusContainer {
  display: flex;
  align-items: center;
}

.statusTitle {
  padding-right: 5px;
}

.statusName {
  font-weight: bold;
  padding-right: 5px;
}
