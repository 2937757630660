@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/settings/fonts';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/tools/mixins';

.wrapper {
  height: remify(104);
  border-radius: remify(8);
  display: flex;

  background-color: palette(neutral, white);
  color: palette(brand, primary);
  cursor: pointer;
}

.statusBar {
  width: remify(12);
  height: 100%;
  background-color: gray;
  border-top-left-radius: remify(8);
  border-bottom-left-radius: remify(8);
  &[data-status='past due'] {
    background-color: palette(status, error);
  }
  &[data-status='open'] {
    background-color: palette(status, warning);
  }
  &[data-status='closed'] {
    background-color: palette(status, complete);
  }
}

.content {
  width: calc(100% - #{remify(12)});
  height: 100%;
  padding: remify(12) remify(16) remify(8) remify(16);

  @include bpXLarge() {
    padding: remify(14) remify(35) remify(8) remify(35);
  }
}
.status {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: font(weight, semibold);
  &[data-status='past due'] {
    color: palette(status, error);
  }
  &[data-status='open'] {
    color: palette(status, warningText);
  }
  &[data-status='closed'] {
    color: palette(status, completeText);
  }
}

.status,
.number {
  font-size: remify(14);
}
.number {
  color: palette(neutral, nevada);
  font-weight: font(weight, semibold);
}
.balance {
  font-size: remify(24);
  font-weight: font(weight, semibold);
  color: black;
}
