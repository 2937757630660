@import '../../assets/styles/settings/global';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/settings/fonts';
@import '../../assets/styles/tools/functions';
@import '../../assets/styles/tools/mixins';

.wrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;
}

.box {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: remify(780);
  min-height: remify(386);
  padding: remify(60);
  background-color: palette(neutral, white);
  border-radius: remify(10);
  box-shadow: 3px 2px 20px 3px rgba(0, 0, 0, 0.6);
}

.box__bd {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ping-logo {
  display: block;
  max-width: 100%;
  width: remify(250);
  height: auto;
  margin-bottom: remify(20);
}

.heading {
  font-size: remify(32);
  font-weight: font(weight, bold);
  margin-bottom: remify(40);
}

.link-list {
  display: flex;
  flex-wrap: wrap;
  font-size: remify(20);
  font-weight: font(weight, semibold);

  li {
    margin-bottom: remify(20);
    margin-right: remify(70);
  }
}

.footer {
  position: absolute;
  bottom: 10px;
}
