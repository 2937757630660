@import '../../assets/styles/settings/global';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/settings/fonts';
@import '../../assets/styles/tools/functions';
@import '../../assets/styles/tools/mixins';

.invoiceGrid {
  @include card(remify(32));
}

.wrapper {
  height: remify(80);
  border-radius: remify(8);
  display: flex;
  background-color: palette(neutral, athens-gray);
  color: palette(neutral, gray);
}

.statusBar {
  width: remify(12);
  height: 100%;
  background-color: gray;
  border-top-left-radius: remify(8);
  border-bottom-left-radius: remify(8);
  &[data-transactionstatus='open'],
  &[data-transactionstatus='Open'],
  &[ata-transactionstatus='OPEN'] {
    color: palette(status, readyText);
    background-color: palette(status, ready);
  }
  &[data-transactionstatus='current'],
  &[data-transactionstatus='Current'],
  &[data-transactionstatus='CURRENT'] {
    color: palette(status, warningText);
    background-color: palette(status, warning);
  }
  &[data-transactionstatus='closed'],
  &[data-transactionstatus='Closed'],
  &[data-transactionstatus='CLOSED'] {
    color: palette(status, completeText);
    background-color: palette(status, complete);
  }
  &[data-transactionstatus='past due'],
  &[data-transactionstatus='Past due'],
  &[data-transactionstatus='PAST DUE'] {
    color: palette(status, error);
    background-color: palette(status, error);
  }
  &[data-transactionstatus='open'],
  &[data-transactionstatus='Open'],
  &[data-transactionstatus='OPEN'] {
    color: palette(status, readyText);
    background-color: palette(status, ready);
  }
}

.content {
  width: calc(100% - #{remify(12)});
  height: 100%;
  padding: remify(12) remify(16) remify(8) remify(16);

  @include bpXLarge() {
    padding: remify(14) remify(35) remify(8) remify(35);
  }
}
.status {
  font-size: remify(14);
  letter-spacing: 1px;
  font-weight: font(weight, semibold);
  @include transactionStatusColors;
}

.balance {
  font-size: remify(24);
  font-weight: font(weight, semibold);
  color: black;
}

.invoice-grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: remify(40);
}

.invoice-grid__list__item {
  width: 100%;
  margin-bottom: remify(20);
  flex-direction: column;
  font-family: font(family, base);
  justify-content: center;

  &:last-child {
    margin-bottom: 0;
  }

  &.grow2 {
    flex-grow: 2;
  }

  &.grow3 {
    flex-grow: 3;
  }

  &.card {
    background-color: palette(neutral, athens-gray);
    border-radius: 6px;
    display: flex;
    font-weight: font(weight, semibold);
    height: remify(75);
    padding: remify(0 20);
  }
}

.invoice-grid--no-margin {
  margin: 0;
}

// had to get specific to override other tag level styles
h1.invoiceHeader {
  margin-bottom: remify(50);
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    margin-left: remify(15);
  }
}

.invoiceHeader__right {
  display: flex;
}

.invoice-grid__list__item .hdg {
  margin-bottom: 10px;
}

.invoice-grid__list__item__label {
  color: palette(neutral, oslo-gray);
  font-size: remify(14);
  line-height: remify(17);
  margin-bottom: 3px;
}

.invoice-grid__list__item__value {
  color: palette(neutral, darker);
  font-size: remify(24);
  line-height: remify(29);
}

@include bpMedium() {
  .invoice-grid__list__item {
    width: calc(50% - 10px);
    &:nth-child(3),
    &:nth-child(4) {
      margin-bottom: 0;
    }
  }
  .invoice-grid__list__item:nth-child(odd) {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 1075px) {
  .invoice-grid__list__item {
    width: calc(25% - 15px);
    margin-right: 20px;
  }

  .invoice-grid__list__item:nth-child(4n) {
    margin-right: 0;
  }

  .invoice-grid__list__item:last-child {
    margin-right: 0;
  }
}

.product-list-table,
.basic-table {
  border-radius: $base-border-radius;
  width: 100%;
}

.product-list-table__row--hd,
.basic-table__row--hd {
  border-bottom: 4px solid black;
  font-weight: font(weight, semibold);
}

.product-list-table__row--bd,
.basic-table__row--bd {
  background-color: palette(neutral, white);
  display: grid;
  border-bottom: 1px solid palette(neutral, iron);

  &:last-child {
    border-bottom: 0;
  }
}

.product-list-table__row__cell,
.basic-table__row__cell {
  font-size: remify(15);
  overflow: hidden;
  padding: remify(6 24);
  text-align: center;
  vertical-align: middle;

  @include bpXLarge() {
    font-size: remify(16);
  }

  &:nth-child(2) {
    text-align: left;
  }
}

.basic-table__row__cell {
  text-align: left;
}

.product-list-table__row__cell__label,
.basic-table__row__cell__label {
  font-weight: font(weight, semibold);
  margin-right: remify(10);
}

.product-list-table__row--bd .product-list-table__row__cell:first-child,
.basic-table__row--bd .basic-table__row__cell:first-child {
  padding-top: remify(24);
}

.product-list-table__row--bd .product-list-table__row__cell:last-child,
.basic-table__row--bd .basic-table__row__cell:last-child {
  padding-bottom: remify(24);
}

.text--truncate {
  @include truncate();
}

.product-list-table__row,
.product-list-table__row--hd,
.product-list-table__row--bd,
.basic-table__row,
.basic-table__row--hd,
.basic-table__row--bd {
  display: table-row;
}

@include bpLarge() {
  .product-list-table__row--hd,
  .basic-table__row--hd {
    height: remify(70);
  }

  .product-list-table__row--bd,
  .basic-table__row--bd {
    @include bpLarge() {
      height: remify(70);
    }

    @include bpXLarge() {
      height: remify(85);
    }
  }

  .product-list-table__row__cell,
  .basic-table__row__cell {
    padding: remify(0 5);
  }

  .product-list-table__row__cell__category,
  .basic-table__row__cell__category {
    color: palette(neutral, gray-chateau);
  }

  .product-list-table__row--bd .product-list-table__row__cell:first-child,
  .basic-table__row--bd .basic-table__row__cell:first-child {
    padding-top: 0;
  }

  .product-list-table__row--bd .product-list-table__row__cell:last-child,
  .basic-table__row--bd .basic-table__row__cell:last-child {
    padding-bottom: 0;
  }

  .text--truncate {
    display: block;
  }
}

.invoice-grid-total {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-right: remify(24);
}

.invoice-grid__total__item {
  text-align: right;
  font-weight: bold;
  margin-bottom: remify(20);
  flex-direction: column;
  font-size: remify(18);
  font-family: font(family, base);
  justify-content: center;
  width: 80%;

  &:nth-child(even) {
    width: 20%;
  }
}

.invoice-grid__total__item--thin {
  font-weight: normal;
}

.invoice-details {
  text-align: right;
  margin-bottom: remify(20);
}

.invoice-details__date {
  color: palette(neutral, oslo-gray);
  font-size: remify(17.5);
}

.btn__download {
  margin-right: remify(20);
}

.btn__download__label {
  @include isVisuallyHidden;
}

.matched-grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  > * {
    width: calc((100% / 2) - 30px);
  }
}

.matched-grid__item {
  margin-bottom: remify(16);
  flex-direction: column;
  font-family: font(family, base);
  justify-content: center;
}

.matched-grid_toFrom {
  background-color: black;
  color: white;
  width: remify(72);
  padding: remify(4 4 4 12);
  border-radius: remify(4);
  margin-left: remify(8);
}

.logo {
  margin-bottom: 20px;
}

.logo__label {
  @include isVisuallyHidden;
}

.btn__expand__label {
  @include isVisuallyHidden;
}

.btn__expand {
  border: 2px solid palette(brand, secondary);
  background-color: palette(neutral, white);
  color: palette(brand, secondary);
  transition: background-color $transition-timing ease-in-out, color $transition-timing ease-in-out;
}

.btn__expand__toggle {
  font-size: remify(30);
  font-weight: bold;
}

.btn__expand:hover,
.btn__expand:focus {
  background-color: palette(brand, secondary);
  border: 2px solid palette(brand, secondary);
  color: palette(neutral, white);
}

.transactions_wrapper {
  display: block;
  &.closed {
    display: none;
  }
  &.open {
    display: block;
  }
}

.loading-message {
  text-align: center;
  padding: 10px;
}
