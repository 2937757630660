@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/tools/mixins';

.subNavMenu__Link {
  margin-left: remify(52);
  margin-top: remify(20);

  &:nth-of-type(1) {
    margin-top: 0;
  }
}

.dropdownContent {
  padding-bottom: remify(20);

  @include bpLarge() {
    padding-bottom: remify(24);
  }
}

.navMenu_Link {
  align-items: center;
  color: palette(neutral, mine-shaft);
  cursor: pointer;
  font-size: remify(16);
  margin: remify(12);
  position: relative;
  transition: background-color 0.1s ease, color 0.1s ease;
  white-space: nowrap;
}

.navMenu_Link:global(.active),
.navMenu_Link:hover,
.navMenu_Link:focus {
  padding: remify(0 50 0 10);
  background-color: palette(neutral, white);
}
