@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/settings/fonts';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/tools/mixins';

.foundLocationContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  text-align: center;
  &_header {
    font-size: remify(22);
    font-weight: bold;
    padding: remify(9);
  }
  &_text {
    padding-bottom: remify(26);
  }
  &_btnText {
    padding-left: remify(40);
    padding-right: remify(40);
  }
  &_form {
    width: remify(500);
    padding-bottom: remify(26);
  }
}
