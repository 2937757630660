@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/settings/fonts';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/tools/mixins';

.orderCartWrapper {
}

.orderCart {
  background-color: white;
  border-radius: 6px;
  padding: 0 remify(10) 0 remify(20);
  font-weight: font(weight, semibold);
  width: remify(230);

  &.orderCartOpen {
    box-shadow: 0px 1px 5px 2px lightgrey;
    position: relative;
    z-index: 100;

    &:after {
      position: absolute;
      bottom: -10px;
      right: 0;
      width: 100%;
      height: 14px;
      background: #fff;
      content: '';
    }

    & .orderCartArrow {
      transform: rotate(180deg);
      margin-top: -10px;
    }
  }
}

.orderWrapper {
  display: flex;
  align-items: center;
  height: remify(80);
  justify-content: space-between;
}

.orderItems {
  color: palette(neutral, nevada);
  font-size: remify(14);
  margin-bottom: remify(5);
}

.orderPopper {
  min-width: remify(700);
  z-index: 99;
  max-width: min-content;
}
