@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/settings/fonts';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/tools/mixins';

.order-confirmation__blue-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #0371b5;
  text-align: center;
  padding: 4rem;
  color: white;
  height: 200px;
  position: relative;
  right: 50%;
  left: 50%;
  margin-left: -720px;
  width: 1440px;
  font-size: 21px;
  margin-top: 30px;

  h1 {
    color: white;
  }
}

.order-confirmation__footer-wrapper {
  padding-bottom: remify(28);
  padding-top: remify(38);
}

.order-confirmation__container {
  display: flex;
  align-items: center;
}

.order-confirmation__confirmation-heading {
  background: transparent;
}

.order-confirmation__number {
  margin-left: remify(15);
  font-weight: 700;
  color: palette(brand, primary);
}
