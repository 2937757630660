.title {
  text-align: center;
}
.message {
  text-align: center;
  padding: 20px 0;
  &_name {
    font-weight: bold;
  }
}
