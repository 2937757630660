@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/settings/fonts';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/tools/mixins';

.modalWrapper {
  background-color: #fff;
  padding: remify(20);
  border-radius: remify(10) 0 remify(10) remify(10);
  box-shadow: 0px 1px 5px 2px lightgrey;
  overflow: hidden;
}

.emptyCart {
  text-align: center;
}

.order-cart__divider {
  @include breakContainer;
  // margin-top: 3.5rem;
  height: 1px;
  border: none;
  background: palette(neutral, iron);

  &--small-margin {
    @extend .order-cart__divider;
    margin-top: remify(20);
  }
}

.order-cart__subtotal-container {
  display: flex;
  position: relative;

  &--review {
    @extend .order-cart__subtotal-container;
    min-height: remify(55);
  }
}

.order-cart__subtotal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: remify(31);
  right: remify(41);
}

.order-cart__review-subtotal-space {
  margin-bottom: remify(37);
}
