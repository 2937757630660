@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/settings/fonts';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/tools/mixins';

.df__cancel-btn {
  color: red;
  font-weight: 700;
  vertical-align: baseline;
  padding: 0;

  &:hover,
  &:focus {
    cursor: pointer;
    text-decoration: underline;
  }
}
.df__cancel-btn-dynamic {
  color: #0371b5;
  font-weight: 700;
  vertical-align: baseline;
  padding: 20px 50px 0 0;

  &:hover,
  &:focus {
    cursor: pointer;
    text-decoration: underline;
  }
}

.df__reset-btn {
  color: red;
  font-weight: 700;
  padding: 0;

  &:hover,
  &:focus {
    cursor: pointer;
    text-decoration: underline;
  }
  border: none;
  background-color: transparent;
  outline: none;
}

.df__divider {
  @include breakContainer;
  margin-top: 3.5rem;
  height: 1px;
  border: none;
  background: palette(neutral, iron);

  &--no-top-margin {
    @extend .df__divider;
    margin-top: 0;
  }

  &--small-margin {
    @extend .df__divider;
    margin-top: remify(20);
  }
}

.df__footer-container {
  padding: 15px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.df__secondSplit {
  width: 100%;
}

.order-footer__modal {
  text-align: center;
}

.order-footer__cancel-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
