@import './../../../assets/styles/settings/global';
@import './../../../assets/styles/tools/mixins';
@import './../../../assets/styles/tools/functions';

.cell {
  display: inline-block;
  margin: 5px;
  vertical-align: middle;
}
.row {
  background-color: white;
  height: remify(56);
  background: white;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  border: 2px solid rgba(0, 0, 0, 0.13);
  border-radius: 7px;
  padding: 18px 10px 20px;
  margin-right: 20px;
  display: flex;
  align-items: center;
}
.first {
  margin-top: 0px;
}

.splitContainer {
  width: 100%;
}
