@import '../../assets/styles/settings/global';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/tools/functions';
@import '../../assets/styles/tools/mixins';
@import '../../assets/styles/settings/fonts';

.statusSuspended {
  color: palette(status, error) !important;
}

.value {
  color: palette(neutral, shark);
  display: block;
  font-size: remify(18);
  font-weight: font(weight, bold);
}

.value--transactionstatus {
  @include transactionStatus();
}

.value--orderstatus {
  @include orderStatus();
}
