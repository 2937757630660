@import '../../assets/styles/settings/global';
@import '../../assets/styles/settings/fonts';
@import '../../assets/styles/tools/functions';

.center {
  text-align: center;
}

.message {
  font-size: remify(24);
  margin-bottom: remify(24);
}

.logo {
  max-width: 100%;
}

.link {
  font-size: remify(18);
  font-weight: font(weight, semibold);
}
