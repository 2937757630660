@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/settings/fonts';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/tools/mixins';

.search-table {
  border-radius: $base-border-radius;
  table-layout: fixed;
  width: 100%;
}

.search-table__row--hd {
  background-color: palette(neutral, white);
  border-bottom: 1px solid palette(neutral, iron);
  display: none;
  font-weight: font(weight, semibold);
}

.search-table__row--bd {
  background-color: palette(neutral, white);
  display: grid;
  border-bottom: 1px solid palette(neutral, iron);

  &:last-child {
    border-bottom: 0;
  }
}

.search-table__row--ft {
  display: grid;
}

.search-table__row__cell {
  font-size: remify(15);
  overflow: hidden;
  padding: remify(6 24);
  text-align: left;
  vertical-align: middle;

  @include bpXLarge() {
    font-size: remify(16);
  }
}

.search-table__row__cell__label {
  font-weight: font(weight, semibold);
  margin-right: remify(10);
}

.search-table__row--bd .search-table__row__cell:first-child {
  padding-top: remify(24);
}

.search-table__row--bd .search-table__row__cell:last-child {
  padding-bottom: remify(24);
}

.text--truncate {
  @include truncate();
}

@include bpLarge() {
  .search-table__row,
  .search-table__row--hd,
  .search-table__row--ft {
    display: table-row;
  }

  .search-table__row--hd {
    height: remify(70);
  }

  .search-table__row--bd {
    @include bpLarge() {
      height: remify(70);
    }

    @include bpXLarge() {
      height: remify(85);
    }
  }

  .search-table__row__cell {
    padding: remify(0 5);

    &:first-child {
      padding-left: remify(24);
    }

    &:last-child {
      padding-right: remify(24);
    }
  }

  .search-table__row__cell__label {
    display: none;
  }

  .search-table__row--bd .search-table__row__cell:first-child {
    padding-top: 0;
  }

  .search-table__row--bd .search-table__row__cell:last-child {
    padding-bottom: 0;
  }

  .text--truncate {
    display: block;
  }
}
