@import '../../../../assets/styles/tools/mixins';
@import '../../../../assets/styles/tools/functions';

.userInitials {
  @include userInitials(88px, 40px);
}

.break {
  word-break: break-all;
}

// These are custom tiles unrelated to the base class, but same concept so using it as the scoped name.
.tiles {
  display: flex;
  flex-direction: column;
  > div:first-child {
    margin-bottom: remify(20);
  }
  @include bpLarge() {
    flex-direction: row;
    > div:first-child {
      width: 88px;
      margin-bottom: 0;
    }
    > div:last-child {
      width: calc(100% - 88px);
      padding-left: remify(30);
    }
  }
}
