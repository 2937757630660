.edit-user__btns {
  margin-bottom: 15px;
  display: flex;
}

.submit {
  margin-left: auto;
}

.edit-user__button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 20px;
}

.edit-user__wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 50px;
}

.userButtons {
  width: calc(100% - 20px);
}

.loading {
  margin-top: 15px;
}
