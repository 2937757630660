@import '../../assets/styles/tools/functions';
@import '../../assets/styles/settings/global';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/settings/fonts';

.global-search {
  display: flex;
  max-width: remify(640);
  width: 87%;

  @media (max-width: $viewport-width-lg) {
    width: 96%;
  }

  &:global(.search--is-narrow) {
    max-width: remify(527);
  }

  &__input {
    padding-left: remify(220);
    padding-top: remify(2);
  }

  // TODO some of these styles could become global select when Order Filters implemented.
  &__select {
    appearance: none;
    background-color: transparent;
    background-image: url('../../assets/media/icons/icon-caret-down.svg');
    background-repeat: no-repeat;
    background-position: right remify(16) top 50%, 0 0;
    background-size: remify(24) auto, 100%;
    border: none;
    border-radius: 0;
    border-right: 1px solid palette(neutral, gray-chateau);
    font-size: remify(18);
    font-weight: font(weight, semibold);
    height: remify(69);
    left: remify(12);
    padding-left: remify(12);
    position: absolute;
    top: 1px;
    width: remify(190);

    &::-ms-expand {
      display: none;
    }
  }
}
