@import '../../assets/styles/settings/global';
@import '../../assets/styles/tools/mixins';
@import '../../assets/styles/tools/functions';

.acc-overview {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;

  :global(.search__input) {
    border-width: remify(1);
    padding-top: remify(4);
  }
}

.acc-overview--split {
  background: transparent;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: remify(30);
}

.tableContainer {
  display: grid;
  grid-template-columns: 45% auto 45%;
  position: relative;
}

.verticalDivider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.verticalLine {
  width: 1px;
  background: palette(neutral, iron);
  height: 100%;
}

.switch_icon {
  color: palette(neutral, gray);
  background-color: palette(neutral, white);
  border-radius: remify(25);
  padding: remify(16);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  svg {
    transform: rotate(90deg);
  }
}
@include bpLarge() {
  .acc-overview {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  .acc-overview--split {
    background: transparent;
    flex-direction: column;
    align-items: flex-start;
  }
}
