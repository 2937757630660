@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/settings/fonts';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/tools/mixins';

.order-detail-table {
  border-radius: $base-border-radius;
  width: 100%;
  line-height: 1.5;
}

.order-detail-table__bold {
  font-weight: 600;
}

.order-detail-table__description-hd {
  color: palette(neutral, oslo-gray);
}

.order-detail-table__row--hd {
  border-bottom: 1px solid palette(neutral, iron);
  font-weight: font(weight, semibold);
}

.order-detail-table__row--bd {
  background-color: palette(neutral, white);
  display: grid;
  border-bottom: 1px solid palette(neutral, iron);

  &:last-child {
    border-bottom: 0;
  }
}
$width: 350px;

.descriptionWithTagName,
.description {
  width: $width;
}
.itemNumber {
  text-align: center;
}
.quantity {
  text-align: center;
}

.order-detail-table__row__cell {
  font-size: remify(15);
  overflow: hidden;
  padding: remify(6 24);
  text-align: center;
  vertical-align: middle;

  @include bpXLarge() {
    font-size: remify(16);
  }

  &:nth-child(2) {
    text-align: left;
  }
}

.order-detail-table__row__cell {
  text-align: left;
}

.order-detail-table__row__cell__label {
  font-weight: font(weight, semibold);
  margin-right: remify(10);
}

.order-detail-table__row--bd .order-detail-table__row__cell:first-child {
  padding-top: remify(24);
}

.order-detail-table__row--bd .order-detail-table__row__cell:last-child {
  padding-bottom: remify(24);
}

.text--truncate {
  @include truncate();
}

.order-detail-table__row,
.order-detail-table__row--hd,
.order-detail-table__row--bd {
  display: table-row;
}

@include bpLarge() {
  .order-detail-table__row--hd {
    height: remify(70);
  }

  .order-detail-table__row--bd {
    @include bpLarge() {
      height: remify(70);
    }

    @include bpXLarge() {
      height: remify(85);
    }
  }

  .order-detail-table__row__cell {
    padding: remify(10 5);
  }

  .order-detail-table__row__cell__category {
    color: palette(neutral, gray-chateau);
    text-transform: capitalize;
  }

  .order-detail-table__row--bd .order-detail-table__row__cell:first-child {
    padding-top: 0;
  }

  .order-detail-table__row--bd .order-detail-table__row__cell:last-child {
    padding-bottom: 0;
    width: 8%;
  }

  .text--truncate {
    display: block;
  }
}

.order-detail-table__status--orderstatus {
  @include orderStatus();
}

.order-detail-table__tracking {
  display: flex;
  flex-direction: row;
}

.order-detail-table__tracking--empty {
  display: flex;
  flex-direction: column;

  span {
    margin-bottom: remify(5);
  }
}

.order-detail-table__button {
  border: none;
  background: transparent;
  cursor: pointer;
}

.order-detail-table__tracking-icon {
  background: palette(brand, primary);
  padding: remify(3);
  border-radius: remify(7);
  display: inline-flex;
  width: remify(14);
  height: remify(14);
  align-items: center;
  justify-content: center;

  svg {
    color: palette(neutral, white);
  }
}
