@import '../../../../assets/styles/settings/colors';

.iconWrapper {
  &:hover,
  &:active {
    cursor: pointer;
    .arrow {
      &::before {
        visibility: visible;
      }
    }
    .tooltip {
      visibility: visible;
    }
  }
}

.info {
  display: flex;
  &.right {
    margin-left: 7px;
  }
  &.left {
    margin-right: 7px;
  }
  .primary {
    fill: palette(brand, primary);
  }
}

.tooltip {
  font-size: 14px;
  line-height: 20px;
  visibility: hidden;
  background: palette(neutral, white);
  color: palette(neutral, black);
  text-align: center;
  border-radius: 4px;
  padding: 8px 12px;
  position: absolute;
  white-space: nowrap;
  z-index: 1;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2542075);
}

.arrow,
.arrow::before {
  position: absolute;
  visibility: hidden;
  width: 10px;
  height: 10px;
  background: inherit;
}

.arrow::before {
  right: 0;
  bottom: 0;
  content: '';
  transform: rotate(45deg);
}

.tooltip[data-popper-placement^='top'] > .arrow {
  bottom: -4px;
}

.tooltip[data-popper-placement^='bottom'] > .arrow {
  top: -4px;
}

.tooltip[data-popper-placement^='left'] > .arrow {
  right: -4px;
}

.tooltip[data-popper-placement^='right'] > .arrow {
  left: -4px;
}
