@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/tools/functions';

.primaryNav-Menu {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: remify(20 0);
  overflow-x: hidden;
}
