@import '../../assets/styles/tools/functions';
@import '../../assets/styles/settings/global';

.create-user__btns {
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: 45% 10%;
  width: 50%;
}

.formButton {
  min-width: 150px;
}

.loading {
  margin-top: 15px;
}
