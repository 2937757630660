@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/settings/fonts';
@import '../../../../assets/styles/tools/mixins';

$COL_1_WIDTH--LG: 16%;
$COL_2_WIDTH--LG: 15%;
$COL_3_WIDTH--LG: 11%;
$COL_4_WIDTH--LG: 23%;
$COL_5_WIDTH--LG: 17%;
$COL_6_WIDTH--LG: 18%;
$COL_1_WIDTH: 33%;
$COL_2_WIDTH: 33%;
$COL_3_WIDTH: 33%;
$COL_4_WIDTH: 33%;
$COL_5_WIDTH: 33%;
$COL_6_WIDTH: 33%;

.orders-card {
  display: flex;
  flex-wrap: wrap;
  min-height: remify(100);
  min-width: remify(440);
  background-color: palette(neutral, white);
  border-radius: remify(7);
  @include vr(1);
}

.section {
  font-weight: font(weight, semibold);
  padding: remify(25 0 30 25);

  &:nth-child(1) {
    width: $COL_1_WIDTH;
    @include bpLarge() {
      width: $COL_1_WIDTH--LG;
    }
  }
  &:nth-child(2) {
    width: $COL_2_WIDTH;
    @include bpLarge() {
      width: $COL_2_WIDTH--LG;
    }
  }
  &:nth-child(3) {
    width: $COL_3_WIDTH;
    @include bpLarge() {
      width: $COL_3_WIDTH--LG;
    }
  }
  &:nth-child(4) {
    width: $COL_4_WIDTH;
    @include bpLarge() {
      width: $COL_4_WIDTH--LG;
    }
  }
  &:nth-child(5) {
    width: $COL_5_WIDTH;
    @include bpLarge() {
      width: $COL_5_WIDTH--LG;
    }
  }
  &:nth-child(6) {
    width: $COL_6_WIDTH;
    @include bpLarge() {
      width: $COL_6_WIDTH--LG;
    }
  }

  &__label {
    margin-bottom: remify(10);
    font-size: remify(14);
    color: palette(neutral, oslo-gray);
  }
}
