@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/tools/mixins';

.primaryNavFooter {
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.primaryNavFooter > a {
  display: flex;
}

$USER_INITIALS_CIRCLE_HEIGHT: 44px;

.primaryNavFooter-userInitials {
  border-radius: 50%;
  background-color: palette(brand, primary);
  color: palette(neutral, white);
  flex-shrink: 0;
  font-size: remify(20);
  font-weight: font(weight, semibold);
  height: $USER_INITIALS_CIRCLE_HEIGHT;
  letter-spacing: 1px;
  line-height: $USER_INITIALS_CIRCLE_HEIGHT;
  margin-left: remify(16);
  margin-right: remify(11);
  text-align: center;
  text-transform: capitalize;
  vertical-align: middle;
  width: $USER_INITIALS_CIRCLE_HEIGHT;
}

:global(.primaryNav.is-collapsed) .primaryNavFooter-userName {
  @include isVisuallyHidden;
}
