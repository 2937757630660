@import '../../../../assets/styles/settings/global';
@import '../../../../assets/styles/settings/colors';
@import '../../../../assets/styles/tools/functions';
@import '../../../../assets/styles/settings/fonts';
@import '../../../../assets/styles/tools/mixins';

$COL_1_WIDTH--LG: 30%;
$COL_2_WIDTH--LG: 40%;
$COL_3_WIDTH--LG: 20%;
$COL_4_WIDTH--LG: 10%;
$COL_1_WIDTH: 50%;
$COL_2_WIDTH: 50%;
$COL_3_WIDTH: 50%;
$COL_4_WIDTH: 50%;

.user-card {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-weight: font(weight, semibold);
  justify-content: space-between;
  min-height: remify(100);
  background-color: palette(neutral, white);
  border-radius: remify(7);
  @include vr(1);

  .user-card__column:nth-child(1) {
    width: $COL_1_WIDTH;
    @include bpLarge() {
      width: $COL_1_WIDTH--LG;
    }
  }
  .user-card__column:nth-child(2) {
    width: $COL_2_WIDTH;
    @include bpLarge() {
      width: $COL_2_WIDTH--LG;
    }
  }
  .user-card__column:nth-child(3) {
    width: $COL_3_WIDTH;
    @include bpLarge() {
      width: $COL_3_WIDTH--LG;
    }
  }
  .user-card__column:nth-child(4) {
    width: $COL_4_WIDTH;
    @include bpLarge() {
      width: $COL_4_WIDTH--LG;
    }
  }
}

.user-card__column {
  padding: remify(25 30 30 25);
}

.user-name {
  align-items: center;
  display: flex;

  > * + *:last-child {
    margin-left: remify(10);
  }
}

.user-initials {
  @include userInitials(40px, 18px);
}

.break {
  word-break: break-all;
}
