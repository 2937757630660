@import './../../assets/styles/settings/colors';
@import './../../assets/styles/settings/global';
@import './../../assets/styles/tools/functions';
@import './../../assets/styles/tools/mixins';
@import './../../assets/styles/settings/fonts';

.lastXDays {
  font-size: remify(16);
  font-weight: font(weight, semibold);
  line-height: remify(12);
  color: palette(neutral, mine-shaft);
}
