@import '../assets/styles/settings/global';
@import '../assets/styles/settings/colors';
@import '../assets/styles/tools/functions';

/* Necessary to push content for left navigation */
.app-main-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  margin-left: remify($primary-nav-collapsed-width);
  transition: margin-left $transition-timing ease-in-out;

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-main-content--no-auth {
  margin-left: 0;
}

.app-main-content--pre-auth {
  background-image: url('/assets/background-cover-optimized.png');
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  align-items: center;
  margin-left: 0;
  padding-left: remify(60);
  padding-right: remify(60);
}

.app-main-content__main-view {
  // Nested flex containers to get Safari to allow the child of this container to fill height
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.primaryNav.is-expanded + .app-main-content {
  margin-left: remify($primary-nav-expanded-width);
  transition: margin-left $transition-timing ease-in-out;
}
