@import '../../assets/styles/settings/global';
@import '../../assets/styles/settings/colors';
@import '../../assets/styles/tools/functions';
@import '../../assets/styles/tools/mixins';

.account-header {
  background-color: palette(brand, primary);
  color: palette(neutral, white);
  display: flex;
  align-items: center;
  font-size: remify(20);
  min-height: remify(100);
  justify-content: space-between;
  padding: remify(25);
}

.account-header__col {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.account-header__col + .account-header__col {
  margin-left: remify(24);
}

.account-header__col > * + * {
  margin-left: remify(12);
}

@include bpLarge() {
  .account-header {
    font-size: remify(24);
    padding: remify(25 80);
  }
}
