.search {
  position: relative;

  &--is-narrow {
    & > * {
      max-height: remify(57);
    }
    & .search__select {
      padding-top: 2px;
    }
    & .search__button {
      right: remify(10);
      top: remify(7);
    }
  }

  &--magnifying-glass::before {
    content: url(../../media/icons/icon-magnifying-glass--gray-chateau.svg);
    height: remify(16);
    left: remify(22);
    position: absolute;
    top: calc(50% - 8px);
    width: remify(16);
  }
  &--magnifying-glass .search__input {
    height: remify(52);
    min-width: remify(465);
    padding-left: remify(54);
    padding-top: remify(6);
  }
  &--magnifying-glass .search__button {
    @include isVisuallyHidden;
  }
}

.search__input {
  border: 1px solid palette(neutral, gray-chateau);
  border-radius: remify(34);
  color: palette(neutral, shark);
  font-family: font(family, base);
  font-size: remify(18);
  font-weight: font(weight, semibold);
  height: remify(71);
  padding-left: remify(28);
  padding-top: remify(4);
  width: 100%;

  // Added specificity to override reset styles
  &[type='search'] {
    box-sizing: border-box;
  }
}

.search__input::placeholder {
  color: palette(neutral, gray-chateau);
  font-size: remify(18);
  font-weight: font(weight, semibold);
}

.search__button {
  color: palette(neutral, white);
  cursor: pointer;
  height: remify(42);
  // must be min-width to override btn class
  min-width: remify(42);
  padding: 0;
  position: absolute;
  right: remify(16);
  top: remify(14);
}
