.icon {
  color: palette(neutral, gray-chateau);
  height: remify(10);
  max-width: 100%;
  width: remify(10);
}

.icon.icon--active {
  color: palette(brand, primary);
}

.icon--link-arrow {
  height: remify(11);
  width: remify(14);
}

.icon--pdf {
  height: remify(19);
  width: remify(16);
}

.icon--link-excel {
  height: remify(22);
  width: remify(19);
}

.icon-serial-sort {
  height: remify(25);
  width: remify(22);
}

.icon--refresh {
  border: none;
  display: inline-block;
  background-color: transparent;
  justify-content: center;
  margin: 0 0;
  padding: 0px;
  color: #0371b5;
  outline: none;

  svg {
    width: 30px;
    transform: rotate(180deg);
    margin-right: 3px;
  }
}
.icon--copy {
  border: none;
  display: inline-block;
  background-color: transparent;
  justify-content: center;
  margin: 0 0;
  padding: 0px;
  color: #0371b5;
  outline: none;
}

[class*='icon--dir'] {
  transition: transform $transition-timing ease-in-out;
}

.icon--dir-0 {
  transform: rotate(0deg);
}

.icon--dir-90 {
  transform: rotate(-90deg);
}

.icon--dir-180 {
  transform: rotate(-180deg);
}

.icon--dir-270 {
  transform: rotate(-270deg);
}
